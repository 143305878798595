import approve from 'approvejs'
import moment from 'moment'

import { errorTitle } from './helper'

export const abbreviateNumber = (value) => {
  let newValue = value;
  if (value >= 1000) {
    const suffixes = ["", "k", "m", "b","t"];
    const suffixNum = Math.floor( (""+value).length/3 );
    let shortValue;
    for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
        if (dotLessShortValue.length <= 2) { break; }
    }
    newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

export const currencyNumber = (value) => {
  if(value === null) return value
  else if(typeof value == 'number') return value.toLocaleString('id-ID', {style: 'currency', currency: 'IDR', minimumFractionDigits: 0})
  else return value
}

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    let context = this;
    let args = arguments;
    
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;
	
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
	
    if (callNow) func.apply(context, args);
  };
}

export const generateMessage = (type, value, callback, timer) => {
  let result 

  result = {
    type: type,
    message: value,
    timer: timer || (type === 'warning' ? 3000 : 2000)
  } 

  if (callback && typeof(callback) == "function") result.callback = () => {
    callback()
  }

  return result || {}
}

export const generateMessageQuestion = (type, title, subtitle, callback, buttonSubmit, cancelCallback) => {
  let result 

  result = {
    type: type,
    title: title,
    subtitle: subtitle,
    callback: callback,
    buttonSubmit: buttonSubmit,
    cancelCallback: cancelCallback
  } 

  return result || {}
}

export const isEqual =  (value, other) => {
	const type = Object.prototype.toString.call(value);
	if (type !== Object.prototype.toString.call(other)) return false;

	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
	const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	const compare = function (item1, item2) {
		const itemType = Object.prototype.toString.call(item1);
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!isEqual(item1, item2)) return false;
		}
		else {
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}

		}
	};

	if (type === '[object Array]') {
		for (let i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (let key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}
	return true;
};

export const generateOptionValue = (value, label) => {
  let result 

  if(value) {
    result = {
      value : value ,
      label: (label ? label : value)
    }
  }

  return result || null
}

export const generateOptionValueArray = (value, isFixedArray) => {
  let result 

  if(Array.isArray(value)) {
    result = []
    for(let val of value) {
      result.push({
        value : val ,
        label: val,
        isFixed: (isFixedArray && isFixedArray.length) ? isFixedArray.includes(val) : false
      })
    }
  }

  return result || null
}

export const isProduction = () => window.location.hostname === "app.forstok.com"

export const humanise = (value) => {
  if(!value) return ''
  const underscoreMatch = /_/g,
    underscoreMatch2 = /-/g

  let  underscoresToSpaces = value.replace(underscoreMatch, " ")
    underscoresToSpaces = underscoresToSpaces.replace(underscoreMatch2, " ")

  return underscoresToSpaces
}

export const paddingNumber = (value, paddingValue, prefix) => {
  if(!value) return ''
  return (prefix || '') + String(paddingValue + value).slice(-paddingValue.length)
}

export const generateAddress = (address) => {
  if(address) {
    const firstLine = ((!address.address1 && !address.address2) ? '' : ((address.address1 ? address.address1 + ', ' : '') + (address.address2 || '') + '<br/>') ),
    secondLine = ((!address.subDistrict && !address.district && !address.city) ? '' : ((address.subDistrict ? address.subDistrict + ', ' : '') + (address.district ? address.district + ', ' : '') + (address.city || '') + '<br/>') ),
    thirdLine = ((address.postalCode ? (address.postalCode + '<br/>') : '')),
    lastLine = address.phone || ''
    return firstLine + secondLine + thirdLine + lastLine
  }
  else return '-'
}

export const evForceInput = (inputEl, value, isDisabled) => {
  if(!inputEl) return false
  const inputValue = () => setTimeout(() => { inputEl.value = value }, 1)
  inputEl.addEventListener("focusin", inputValue , false)
  inputEl.focus()
  inputEl.removeEventListener("focusin", inputValue , false)
}

export const dataURItoBlob = (dataURI) => {
  var byteString = atob(dataURI.split(',')[1])
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
  }
  var blob = new Blob([ab], {type: mimeString})
  return blob
}

export const loadImage = path => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.src = path
    img.onload = () => {
      resolve(img)
    }
    img.onerror = e => {
      reject(e)
    }
  })
}

export const generateValueTable = (input, key) => {
  let result = input
  if(key && input !== '') {
    const _key = key.toLowerCase()
    if(/price/.test(_key)) {
      result = currencyNumber(input)
    }
    else if(/width/.test(_key) || /length/.test(_key) || /height/.test(_key)) {
      result = input + ' cm'
    }
    else if(/weight/.test(_key)) {
      result = input + ' gr'
    }
    else if(/discount/.test(_key)) {
      result = input + ' %'
    }
  }
  return result
}

export const generateValue = (type, value) => {
  let _value
  switch(type) {
    case 'String':
      _value = value ? value.toString().trim() : ''
      break
    case 'Integer':
      _value = (value !== '' && value !== null) ? parseInt(value) : null
      break
    case 'Currency':
      _value = (value !== '' && value !== null) ? parseInt(formatNumber(value, false)) : null
      break
    case 'Boolean':
      case 'Array':
      _value = value
      break
    default:
      _value = value.toString().trim()
  }
  return _value
}

export const validateByApproveJs = (head, value, title, valueMatch) => {
  let result = { approved: true },
      _title = title || errorTitle(head.name)

    if(head.key.indexOf('options-option') !== -1) {
      if(value === '') result = { approved: false, errors: [_title+' is required'] }
    }

    if(head?.validations) {
      const typeData = head?.typeData || 'String'
      let rules = {title: _title}

      if(head.validations?.required) {
        switch(typeData) {
          case 'String':
            rules.required = true
          break
          case 'Integer': 
            case 'Currency':
            const _value = value === null ? '' : (isNaN(value) ? '' : value)
            const requiredIntegerRule = {
              expects: [
                'value'
              ],
              message: '{title} is required',
              validate: (value, pars) => {
                return pars.value !== '' && pars.value !== null
              }
            }
            approve.addTest(requiredIntegerRule, 'requiredIntegerRule')
            rules.requiredIntegerRule = {
              value: _value
            }
          break 
          case 'Array':
            const requiredArrayRule = {
              expects: [
                'value'
              ],
              message: '{title} is required',
              validate: (value, pars) => {
                return ((pars.value && pars.value.length) || (pars.value && Object.keys(pars.value).length)) ? true : false 
              }
            }
            approve.addTest(requiredArrayRule, 'requiredArrayRule')
            rules.requiredArrayRule = {
              value: value
            }
          break
          default: 
            rules.required = true
        }
      }

      if(('min' in head.validations && head.validations.min !== null) && ('max' in head.validations && head.validations.max !== null)) {
        switch(typeData) {
          case 'Integer':
            case 'Currency':
            const minMaxIntegerRule = {
              expects: [
                'value'
              ],
              message: '{title} min '+head.validations.min+' and max '+head.validations.max,
              validate: (value, pars) => {
                return (pars.value >= head.validations.min && pars.value <= head.validations.max) || pars.value === '' || pars.value === null
              }
            }
            approve.addTest(minMaxIntegerRule, 'minMaxIntegerRule')
            rules.minMaxIntegerRule = {
              value: value
            }
          break
          case 'Array':
            const minMaxArrayRule = {
              expects: [
                'value'
              ],
              message: '{title} must be a minimum of '+head.validations.min+' and a maximum of '+head.validations.max,
              validate: (value, pars) => {
                return pars.value ? (pars.value.length >= head.validations.min &&  pars.value.length <= head.validations.max) : (head.validations.min === 0 ? true : false)
              }
            }
            approve.addTest(minMaxArrayRule, 'minMaxArrayRule')
            rules.minMaxArrayRule = {
              value: value
            }
          break
          default: 
            rules.min = head.validations.min
            rules.max = head.validations.max
        }
      }
      else if('min' in head.validations && head.validations.min !== null) {
        switch(typeData) {
          case 'Integer':
            case 'Currency':
            const minIntegerRule = {
              expects: [
                'value'
              ],
              message: '{title} min '+head.validations.min,
              validate: (value, pars) => {
                return pars.value >= head.validations.min || pars.value === '' || pars.value === null
              }
            }
            approve.addTest(minIntegerRule, 'minIntegerRule')
            rules.minIntegerRule = {
              value: value
            }
          break
          case 'Array':
            const minArrayRule = {
              expects: [
                'value'
              ],
              message: '{title} must be a minimum of '+head.validations.min,
              validate: (value, pars) => {
                return pars.value ? (pars.value.length >= head.validations.min) : (head.validations.min === 0 ? true : false)
              }
            }
            approve.addTest(minArrayRule, 'minArrayRule')
            rules.minArrayRule = {
              value: value
            }
          break
          default: 
            rules.min = head.validations.min
        }
      }
      else if('max' in head.validations && head.validations.max !== null) {
        switch(typeData) {
          case 'Integer':
            case 'Currency':
            const maxIntegerRule = {
              expects: [
                'value'
              ],
              message: '{title} max '+head.validations.max,
              validate: (value, pars) => {
                return pars.value <= head.validations.max || pars.value === '' || pars.value === null
              }
            }
            approve.addTest(maxIntegerRule, 'maxIntegerRule')
            rules.maxIntegerRule = {
              value: value
            }
          break
          case 'Array':
            const maxArrayRule = {
              expects: [
                'value'
              ],
              message: '{title} must be a maximum of '+head.validations.max,
              validate: (value, pars) => {
                return pars.value ? (pars.value.length <= head.validations.max) : true
              }
            }
            approve.addTest(maxArrayRule, 'maxArrayRule')
            rules.maxArrayRule = {
              value: value
            }
          break
          default: 
            rules.max = head.validations.max
        }
      }
      if('lessEqual' in head.validations) {
        const lessEqualRule = {
          expects: [
            'value',
            'valueMatch',
          ],
          message: '{title} is less than or equal {valueMatch}',
          validate: (value, pars) => {
            return (IsNumeric(value) && IsNumeric(pars?.valueMatch)) ? (value <= pars.valueMatch) : true
          }
        }
        approve.addTest(lessEqualRule, 'lessEqualRule')
        rules.lessEqualRule = {
          value: value,
          valueMatch: valueMatch
        }
      }
      if('less' in head.validations) {
        const lessRule = {
          expects: [
            'value',
            'valueMatch',
          ],
          message: '{title} is less than {valueMatch}',
          validate: (value, pars) => {
            return (IsNumeric(value) && IsNumeric(pars?.valueMatch)) ? (value < pars.valueMatch) : true
          }
        }
        approve.addTest(lessRule, 'lessRule')
        rules.lessRule = {
          value: value,
          valueMatch: valueMatch 
        }
      }
      if(head.validations?.greaterEqual) {
        const greaterEqualRule = {
          expects: [
            'value',
            'valueMatch',
          ],
          message: '{title} is greater than or equal {valueMatch}',
          validate: (value, pars) => {
            return (IsNumeric(value) && IsNumeric(pars?.valueMatch)) ? (value >= pars.valueMatch) : true
          }
        }
        approve.addTest(greaterEqualRule, 'greaterEqualRule')
        rules.greaterEqualRule = {
          value: value,
          valueMatch: valueMatch
        }
      }
      if(head.validations?.greater) {
        const greaterRule = {
          expects: [
            'value',
            'valueMatch',
          ],
          message: '{title} is greater than {valueMatch}',
          validate: (value, pars) => {
            return (IsNumeric(value) && IsNumeric(pars?.valueMatch)) ? (value > pars.valueMatch) : true
          }
        }
        approve.addTest(greaterRule, 'greaterRule')
        rules.greaterRule = {
          value: value,
          valueMatch: valueMatch
        }
      }
      result = approve.value(value, rules)
    }
    return result
} 

export const unescapeHTML = (value) => {
  return value.replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
}

export const getStorage = (name, defaultVal, jsons = true, loadName= 'load') => {
  const defaultValue = defaultVal
  let result = defaultValue
  if(sessionStorage.getItem(loadName)) {
    result = sessionStorage.getItem(name) || defaultValue
  }else {
    result = localStorage.getItem(name) || defaultValue
    sessionStorage.setItem(name, result)
  }
  jsons = (result === 'all' || result === undefined || result === 'undefined' || (Array.isArray(result) && !result.length) ) ? false : jsons
  const reResult = (result  === undefined || result === 'undefined') ? {} : result
  return jsons ? JSON.parse(reResult) : reResult
}

export const setStorage = (name, value, only) => {
  switch(only) {
    case 'session':
      sessionStorage.setItem(name, value)
      break
    case 'local':
      localStorage.setItem(name, value)
      break
    default:
      sessionStorage.setItem(name, value)
      localStorage.setItem(name, value)
  }
  return true
}

export const unCamelCaseKeys = (object) => {  
  const unCamelCase = (value) => {
    return value.replace(/([a-z][A-Z])/g, function (g) { return g[0] + '_' + g[1].toLowerCase() })
  }
  return Object
    .entries(object)
    .reduce((carry, [key, value]) => {
      carry[unCamelCase(key)] = value
      return carry
    }, {})
}

export const generateOriginKey = (key, arrString) => {
  let result = key
  for(let arr of arrString) result = result.replace(arr, '')
  return result
}

export const generateDefaultValue = (field) => {
  let _value = ''
  switch(field.fieldType) {
    case 'select':
    case 'select_async_pagination':
      _value = null
      if (field.key === 'variants-tax' && !_value) {
        const taxEl = document.querySelector('#tax')
        if (taxEl && field.options?.length) {
          _value = taxEl.innerHTML === 'true'
        }
      }
      break
    case 'image':
      _value = (field.typeData === 'Array') ? null : ''
      break
    case 'tag':
      _value = (field.typeData === 'Array') ? null : ''
      break
    case 'date':
      _value = field.validations?.required ? moment(new Date()).format("DD/MM/YYYY") : ''
      break  
    case 'input':
      _value = (field.typeData === 'Integer') ? null : ''
      break    
    default:
      _value = ''
  }
  return _value
}

export const capitalize = (value) => {
  return value ? (value[0].toUpperCase() + value.substring(1)) : ''
}

export const pasteIntoInput = (el, text) => {
  el.focus()
  if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
    var val = el.value
    var selStart = el.selectionStart
    el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd)
    el.selectionEnd = el.selectionStart = selStart + text.length
  } else if (typeof document.selection != "undefined") {
    var textRange = document.selection.createRange()
    textRange.text = text
    textRange.collapse(false)
    textRange.select()
  }
}

export const slugify = (string) => {
  if(!string) return ''

  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "")
}

export const generateAutoCopyKey = (keyAlias, variantIdx, optionIdx, header) => {
  let result = keyAlias
  if(header?.condition === 'variant') result += '-variant-'+variantIdx
  if(keyAlias === 'options-type' || keyAlias === 'options-option') result += '-'+optionIdx
  return result
}

export const isErrorEscape = (error) => {
  return error && error?.networkError?.name !== 'AbortError'
}

export const getStyleWidthbyMediaQuery = () => {
  const width = document.body.clientWidth

  let result = 1024
  if(width >= 1680) {
    result = 1680
  } else if(width >= 1440) {
    result = 1440
  } else if(width >= 1280) {
    result = 1280
  } else if(width >= 1024) {
    result = 1024
  }

  return result
}

export const generateSUM = (names, data) => {
  let result = 0
  if(data && names) {
    result = data.reduce((_sum, cur) => {
      let _name
      if (Array.isArray(names)) {
        _name = cur
        for (const name of names) {
          if (name in _name) {
            _name = _name[name]
          }
        }
      } else {
        _name = cur[names]
      }
      return _sum + _name
    } ,0)
  }
  return result
}

export const getURLExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

export const evCompareCacheListing = (existing, incoming, args, readField) => {
  let result = true
  // console.log(args, "args", existing, incoming)
  if (incoming?.edges && existing?.edges) {
    if (incoming.edges.length === existing.edges.length) {
      let BreakException = {}
      try {
        console.group("cache", args)
        existing.edges.forEach((listing, idx) => {
          const _listing = readField("node", listing)
          const _id = readField("id", _listing)
          const _variants = readField("variants", _listing)
          const listingInc = incoming.edges[idx]
          const _listingInc = readField("node", listingInc)
          const _idInc = readField("id", _listingInc)
          const _variantsInc = readField("variants", _listingInc)
          console.log(_id, "(existing)" , "=", _idInc, "(incoming)", "listing.id")
          if (_id !== _idInc) throw BreakException
          if(_variants?.length === _variantsInc?.length) {
            _variants.forEach((variant, variantIdx) => {
              const _id = readField("id", variant)
              const _idInc = readField("id", _variantsInc[variantIdx])
              console.log(_id, "(existing)" , "=", _idInc, "(incoming)", "listing.variant.id")
              if (_id !== _idInc) throw BreakException
            })
          }else throw BreakException
        })
        console.groupEnd()
      } catch (e) {
        if (e !== BreakException) {
          throw e
        } else {
          console.warn("different ->", existing, incoming, args)
          result = false
        }
      }
    } else {
      result = false
    }
  }

  return result
}

export const evUpdateInputRc = (input, value) => {
  if (!input) return false
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set
  nativeInputValueSetter.call(input, (value === undefined ? '' : value))
  const inputEvent = new Event('input', { bubbles: true})
  input.dispatchEvent(inputEvent)
}

export const evGenerateValueMatch = (validation, data) => {
  let result = undefined
  if (validation && Object.keys(validation).length) {
    const keyArr = ['lessEqual', 'greaterEqual', 'less', 'greater']
    const key = keyArr.find((_key) => _key in validation)
    if (data && key) {
      const obj = validation[key]
      if(obj && obj in data) result = data[obj]
    }
  }
  return result
}

export const evScrollTo = (el, range) => {
  const _range = range || 140
  if (el) {
    setTimeout(() => {
      const viewportEl = document.querySelector('._refMasterTableViewport')
      if (!viewportEl) return
      
      const bodyRect = viewportEl.getBoundingClientRect(),
            elemRect = el.getBoundingClientRect()
      if(elemRect) {
        const offsetTop = (elemRect.top - bodyRect.top - _range) + viewportEl.scrollTop,
              offsetLeft = (elemRect.left - bodyRect.left) + viewportEl.scrollLeft
        viewportEl.scrollTo(offsetLeft,offsetTop)
      }      
    }, 20)
  }
}

export const evSortbySku = (results) => {
  return results.sort((a, b) => {
    const aValue = a?.product?.sku || ''
    const bValue = b?.product?.sku || ''
    if (aValue < bValue) return 1
    if (aValue > bValue) return -1
    return 0
  })
}

export const evRenameImage = (name) => {
  const ext = (/[.]/.exec(name)) ? /[^.]+$/.exec(name) : undefined
  let result = name.replace(/\.[^/.]+$/, "")
  result = result.replace(/[&\\/\\#, +()$~%.'":@^*?<>{}]/g, '')
  if (ext) result += '.'+ext
  return result
}

export const evLoadingMasterTable = (flag) => {
  const loadingWrapperEl = document.querySelector('.loadingWrapper')
  if (!loadingWrapperEl) return false
  return flag ? loadingWrapperEl.classList.remove('hidden') : loadingWrapperEl.classList.add('hidden')
} 

export const formatNumber = (n, flag) => {
  const _flag = flag === undefined ? true : flag
  let result = ''
  if (n !== '' && n !== null && n !== undefined) {
    result = n?.toString().replaceAll(".", "")
    if (result.length === 2 && result.substring(0, 1) === "0") result = parseInt(result).toString()
    if (_flag) result = result.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  }
  return result
}

export const IsNumeric = (val) => {
  return Number(parseFloat(val)) === val
}

export const evHighlight = (ids) => {
  if (!ids?.length) return
  const timer = Math.ceil(ids.length/10)*10 <= 10 ? 30 : (Math.ceil(ids.length/10)*10 >= 200 ? 200 : Math.ceil(ids.length/10)*10) 
  setTimeout(() => {
    const contentHightlightedEls = document.querySelectorAll('._refMasterTableBodyContent.is-highlighted')
    if (contentHightlightedEls?.length) {
      for(const contentHightlightedEl of contentHightlightedEls) {
        contentHightlightedEl.classList.remove('is-highlighted')
      }
    }
    for(let i = 0; i < ids.length; i++) {
      const id = ids[i]
      const el = document.querySelector(`#row-${id}`)
      if (el) {
        el.classList.add('is-highlighted')
        setTimeout(() => {
          el.classList.remove('is-highlighted')
        }, 1888)
      }
      if(i === (ids.length - 1)) {
        const firstEl = document.querySelector(`#row-${ids[0]}`)
        firstEl && evScrollTo(firstEl, 140)
      }
    }
  }, timer)
}

export const evGenerateDataPriceTable = (data, response) => {
  let newData = [...data]
  let ids = []
  if (response.GetVariantProducts?.length) {
    response.GetVariantProducts.forEach((variant, idx) => {
      const productIdx = newData.findIndex(_product => _product.id === variant.id)
      if (productIdx < 0) {
        let product = {
          id: variant.id,
          item: {
              imageUrl: variant.imageUrl,
              name: variant.variantName,
              sku: variant.sku,
              barcode: variant.barcode
          },
          options: variant.options ? (variant.options.reduce((str, option, index) => str + `${index? ', ' : ''}${option.option || '-'}`, '')) : '-',
          price: variant.regularPrice,
          price_base: variant.regularPrice,
          salePrice: variant.salePrice,
          salePrice_base: variant.salePrice
        }
        if (variant.listings?.length) {
          variant.listings.forEach(listing => {
            product[`${listing.accountId}_listingId`] = listing.listingId
            product[`${listing.accountId}_price`] = listing.price
            product[`${listing.accountId}_price_base`] = listing.price
            if (listing.salePrice !== null) {
              product[`${listing.accountId}_salePrice`] = listing.salePrice.salePrice
              product[`${listing.accountId}_salePrice_base`] = listing.salePrice.salePrice
              product[`${listing.accountId}_saleStartAt`] = listing.salePrice.startAt
              product[`${listing.accountId}_saleStartAt_base`] = listing.salePrice.startAt
              product[`${listing.accountId}_saleEndAt`] = listing.salePrice.endAt
              product[`${listing.accountId}_saleEndAt_base`] = listing.salePrice.endAt
            }
          })
        }
        newData.push(product)
      }
    })
    ids = response.GetVariantProducts.map(variant => variant.id)
  }
  return {
    data: newData,
    ids: ids
  }
}

export const evGenerateDataStockTable = (data, response, type, isScan) => {
  let newData = [...data]
  let ids = []
  if (response.GetVariantProducts?.length) {
    response.GetVariantProducts.forEach((variant, idx) => {
      const productIdx = newData.findIndex(_product => _product.id === variant.id)
      if (productIdx < 0) {
        let product = {
          id: variant.id,
          item: {
              imageUrl: variant.imageUrl,
              name: variant.productName,
              sku: variant.sku,
              barcode: variant.barcode
          },
          options: variant.options ? (variant.options.reduce((str, option, index) => str + `${index? ', ' : ''}${option.option || '-'}`, '')) : '-',
          quantity: 1,
        }
        if (type === 'adjustment' || type === 'inbound' || type === 'outbound') {
          product.qtyOnHand = variant.warehouse?.[0]?.quantityOnHand
          product.qtyReserved = variant.warehouse?.[0]?.reservedQuantity
        }
        if(type === 'adjustment') {
          product.quantity = null
          product.qtyAdjusted = ''
        } else if(type === 'inbound') {
          product.quantity = 1
        }
        newData.push(product)
      } else if (isScan) {
        const productExt = {...newData[productIdx]}
        if(type !== 'adjustment') {
          const qty = productExt.quantity + 1
          productExt.quantity = qty
        }
        newData.splice(productIdx, 1, productExt)
      }
    })
    ids = response.GetVariantProducts.map(variant => variant.id)
  }
  return {
    data: newData,
    ids: ids
  }
}

export const evKeyMapperScanner = (oEvent) => {
  var iCode = oEvent.which
  switch (true) {
    case iCode >= 48 && iCode <= 90:
    case (iCode >= 106 && iCode <= 111) || iCode === 45 || iCode === 173 || iCode === 189:
      if (oEvent.key !== undefined && oEvent.key !== '') {
        return oEvent.key
      }
      var sDecoded = String.fromCharCode(iCode)
      switch (oEvent.shiftKey) {
        case false: 
          sDecoded = sDecoded.toLowerCase()
          break
        case true: 
          sDecoded = sDecoded.toUpperCase()
          break
        default:
          break
      }
      return sDecoded
    case iCode >= 96 && iCode <= 105:
      return 0+(iCode-96)
    default:
      break
  }
  return ''
}