import { gql } from '@apollo/client'

/* PROFILE */
export const QUERY_GET_PROFILE = gql`
  query profile {
    profile {
      id 
      name
      mwhActivated
      tax {
        setting
        rate
      }
      isShipper
      invoice {
        isAutoCreate
        paymentTerms
        statusAutoCreate
        termsAndConditions
        isAutoPaid
        paymentDateOption
      }
    }
  }
`
export const QUERY_GET_CURRENT_DASHBOARD_USER = gql`
  query currentDashboardUser {
    currentDashboardUser {
      name
      email
    }
  }
`
export const QUERY_GET_LIST_ACCOUNTS = gql`
  query accountList {
    accounts {
      id
      name
      isSyncAvailable
      isSyncing
      isSyncFileNeeded
      isSyncItem
      isStore
      lastSyncedAt
      channel {
        id
        name
        isPos
        initials
        color
        icon
      }
      hasCategory
    }
  }
` 
export const MUTATION_TOOGLE_LIST_ACCOUNTS_CLIENT = gql`
  mutation ToogleAccountListClient($updates: updates!) {
    toogleAccountListClient(updates: $updates) @client
  }
`

/* ACTIVITY */
export const QUERY_GET_LIST_ACTIVITIES = gql`
  query activityProfileList($first: Int!) {
    profileActivities(first: $first) {
      edges {
        node {
          action
          actionType
          attempts
          countPercentage
          currentCount
          failed
          failedCount
          id
          inProgress
          links {
            label
            url
          }
          processFinishedAt
          processTime
          processStartedAt
          resultUrl
          status
          successCount
          totalCount
          uploadedUrl
          userEmail
          userId
        }
      }
    }
  }
`
export const QUERY_GET_LIST_ACTIVITY_LOG = gql`
  query activityProfileList($first: Int, $after: String, $before: String, $endDate: String, $filterBy: ActivityFilterByInputs, $last: Int, $search: String, $searchBy: String, $startDate: String) {
    profileActivities(first: $first, after: $after, before: $before, endDate: $endDate, filterBy: $filterBy, last: $last, search: $search, searchBy: $searchBy, startDate: $startDate) {
      edges {
        node {
          id
          action
          type
          failedCount
          links {
            label
            url
          }
          processStartedAt
          status
          successCount
          userEmail
          source
        }
      }
    }
  }
`
export const QUERY_GET_PAGE_ACTIVITY_LOG = gql`
  query activityProfilePage($first: Int, $after: String, $before: String, $endDate: String, $filterBy: ActivityFilterByInputs, $last: Int, $search: String, $searchBy: String, $startDate: String) {
    profileActivities(first: $first, after: $after, before: $before, endDate: $endDate, filterBy: $filterBy, last: $last, search: $search, searchBy: $searchBy, startDate: $startDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

/* DASHBOARD HOME */
export const QUERY_GET_LIST_DRAFTS = gql`
  query draftList {
    drafts {
      id
      name
      totalSku
    }
  }
`
export const QUERY_GET_LIST_PENDING_ORDERS = gql`
  query orderPendingList {
    pendingOrders {
      totalPendingOrders
      data {
        id
        name
        initials
        color
        icon
        pendingOrders
        pendingOrders48More
        pendingOrders2448
        pendingOrders024
      }
    }
  }
`
export const QUERY_GET_SUMMARY_LIST_STATUS_ITEMS = gql`
  query statusItemSummaryList {
    listingSummary {
      totalInStock
      total
      totalOutOfStock
      data {
          all
          color
          icon
          id
          inactive
          initials
          live
          missingImage
          name
          all
          outOfStock
          pendingAction
          qcPending
          qcRejected
      }
    }
  }
`
export const QUERY_GET_SUMMARY_CART_LIST_SALES = gql`
  query salesCartSummaryList($storeId: Int!, $channelId: String!, $startDate: String!, $endDate: String!) {
    saleSummaries(storeId: $storeId, channelId: $channelId, startDate: $startDate, endDate: $endDate) {
      timeRange 
      avgCommissions{
        percentage
        symbol
        value
      }
      avgItemDiscount{
        percentage
        symbol
        value
      }
      avgOrderValue{
        percentage
        symbol
        value
      }
      avgOrdersPerDay{
        percentage
        symbol
        value
      }
      commissions{
        percentage
        symbol
        value
      }
      grossSales{
        percentage
        symbol
        value
      }
      itemsSold{
        percentage
        symbol
        value
      }
      netSales{
        percentage
        symbol
        value
      }
      orders{
        percentage
        symbol
        value
      }
      shippingPrice{
        percentage
        symbol
        value
      }
      data {
        channelName
        color
        commission
        grossSales
        icon
        id
        initials
        itemSold
        logo
        name
        netSales
        orders
        shipping
        avgOrderValue
        subTotal
      }
    }
    orderChartSupport(storeId: $storeId, startDate: $startDate, endDate: $endDate) {
      timeFormat
      orders{
        label
        totalPrice
      }
    }
  }
`
export const QUERY_GET_ORDER_TO_FULFILL = gql` 
  query orderToFulfillList ($startDate: String, $endDate: String, $groupFulfillmentType: String) {
    orderToFulfill (startDate: $startDate, endDate: $endDate, groupFulfillmentType: $groupFulfillmentType) {
      data {
        storeId
        storeName
        channelId
        channelName
        channelColor
        channelImage
        channelInitials
        pendingOrders
        openOrders
        notShippedOrders
        readyToShipOrders
      }
      total {
        totalOrders
        totalPending
        totalOpen
        totalNotShipped
        totalReadyToShip
      }
    }
  }
`
export const QUERY_GET_LIST_TOTAL_SKU = gql`
  query totalSkuList {
    listingTotalSkus {
      data {
        channel {
          channelName
          channelInitials
          channelColor
          channelImage
          channelId
          navbarId
        }
        All
        Live
        Inactive
        Sold
        Failed
      }
      TotalMasterProduct
      TotalBundle
    }
  }
`
export const QUERY_GET_LIST_TOTAL_MASTER = gql`
  query totalSku {
    listingTotalSkus {
      TotalMasterProduct
    }
  }
`
export const MUTATION_DELETE_DRAFT_ITEM = gql`
  mutation itemDraftDelete($id: ID!) {
    draftDelete(id: $id) {
      id
      message
      success
    }
  }
`

/* USER & ROLE */
export const QUERY_GET_LIST_USERS = gql`
  query userList {
    dashboardUsers {
      id
      name
      email
      role {
        id
        name
      }
      isActive
    }
  }
`
export const QUERY_GET_USER = gql`
  query user($id: Int!) {
    dashboardUser(id: $id) {
      id
      name
      email
      role {
        id
        name
      }
      roleId
      isActive
    }
  }
`
export const QUERY_GET_LIST_PERMISSIONS = gql`
  query permissionList {
    permissions{
      enableWarehouse
      group
      id
      name
    }
  }
`
export const QUERY_GET_LIST_WAREHOUSES = gql`
  query warehouseList {
    warehouses{
      id
      address {
        id
        address
        postalCode
        subDistrictID
        subDistrict {
          id
          name
          districtId
          district {
            id
            name
            cityId
            city {
              id
              name
              provinceId
              province {
                id
                name
                countryId
                country {
                  id
                  name
                }
              }
            }
          }
        }
      }
      isDeletable
      code
      default
      status
      name
      profileID
      updatedAt
      isPos
    }
  }
`
export const QUERY_GET_LIST_STORE = gql`
  query storeList($onlySalesOrder: Boolean) {
    accounts(onlySalesOrder: $onlySalesOrder){
      id
      name
      imageUrl
    }
  }
`
export const QUERY_GET_LIST_ROLES = gql`
  query roleList {
    roles {
      id
      name
      description
      dashboardUserCount
    }
  }
`
export const QUERY_GET_ROLE = gql`
  query role($id: Int!) {
    role(id: $id) {
      id
      name
      description
      dashboardUserCount
      permissions {
        group
        id
        name
      }
      rolePermissions {
        id
        permission {
          enableWarehouse
          group
          id
          name
        }
        warehouseIds
      }
    }
  }
`
export const MUTATION_CREATE_USER = gql`
  mutation userCreate($input: CreateDashboardUserInput!) {
    createDashboardUser(input: $input) {
      dashboardUser {
        id
        name
        email
        roleId
        role {
          id
          name
        }
        isActive
      }
    }
  }
`
export const MUTATION_UPDATE_USER = gql`
  mutation userUpdate($input: UpdateDashboardUserInput!) {
    updateDashboardUser(input: $input) {
      dashboardUser {
        id
        name
        email
        role {
          id
          name
        }
        roleId
        isActive
      }
    }
  }
`
export const MUTATION_DELETE_USER = gql`
  mutation userDelete($input: DeleteDashboardUserInput!) {
    deleteDashboardUser(input: $input) {
      dashboardUser {
        id
      }
    }
  }
`
export const MUTATION_CREATE_ROLE = gql`
  mutation roleCreate($input: CreateRoleInput!) {
    createRole(input: $input) {
      role {
        id
        name
        description
        dashboardUserCount
        permissions {
          group
          id
          name
        }
        rolePermissions {
          id
          permission {
            enableWarehouse
            group
            id
            name
          }
          warehouseIds
        }
      }
    }
  }
`
export const MUTATION_UPDATE_ROLE = gql`
  mutation roleUpdate($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      role {
        id
        name
        description
        dashboardUserCount
        permissions {
          group
          id
          name
        }
        rolePermissions {
          id
          permission {
            enableWarehouse
            group
            id
            name
          }
          warehouseIds
        }
      }
    }
  }
`
export const MUTATION_DELETE_ROLE = gql`
  mutation roleDelete($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      role {
        id
      }
    }
  }
`
export const MUTATION_CREATE_STORE = gql`
  mutation storeCreate($input: CreateSalesOrderAccountInput!) {
    createSalesOrderAccount(input: $input) {
      account {
        id
        name
        imageUrl
      }
    }
  }
`
export const MUTATION_UPDATE_ACCOUNT = gql`
  mutation accountUpdate($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
        name
        imageUrl
      }
    }
  }
`

/* ITEM */
export const _QUERY_GET_LIST_PACKAGES = gql`
  query packageList($search: String) {
    packages(search: $search) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export const QUERY_GET_LIST_MASTER_CATEGORIES = gql`
  query masterCategories {
    masterCategories {
      id
      name 
    }
  }
`

export const MUTATION_SAVE_DRAFT_PRODUCT = gql`
  mutation productSaveDraft($input: SaveDraftInput!) {
    saveDraftProduct(input: $input) {
      message
      draftId
    }
  }
`

export const QUERY_GET_HEADER_TOTAL_ITEM = gql `
  query pendingActionList{
    listingPendingAction{
      TotalSkus
    }
  }
`

const product = gql`
  fragment product on Product {
    id
    productId
    listingId
    kind
    profileId
    name
    category
    brand
    description
    imageUrl
    tags
    totalBundle
    variants{
      id
      variantListingId
      bundleId
      totalListing
      sku
      imageUrl
      quantityOnHand
      reservedQuantity
      availableQuantity
      quantity
      regularPrice
      salePrice
      costPrice
      weight
      height
      length
      width
      tax
      barcode
      options{
        type
        option
      }
      status{
        label
        name
        count
      }
    }
    createdAt
    updatedAt
    draftId
  }
`

export const QUERY_GET_DETAIL_PRODUCT = gql`
  query detailProduct($id: Int, $sku: String){
    product(id:$id, sku:$sku){
      ...product
    }
  }
  ${product}
`

export const QUERY_GET_TOTAL_PRODUCT = gql`
  query totalProduct{
    products{
      totalCount
    }
  }
`

export const MUTATION_SUBMIT_PRODUCT = gql`
  mutation productActions($input: ActionProductsInput!) {
    actionProducts(input: $input) {
      message
      errors {
        message
        row
        variantIndex
        key
      }
      productIds
    }
  }
`

export const MUTATION_DELETE_PRODUCT = gql`
  mutation productDelete ($input: deleteProductsInput!){
    deleteProducts(input:$input){
      message
      successProducts
      failedProducts
    }
  }
`

export const QUERY_GET_PAGE_PRODUCT = gql`
  query productPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $filterBy: ProductFilterByInputs, $sortBy: LinkProductByInput, $search: String, $searchBy: String, $ids: [Int], $listingIds:[String]) {
    products(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, filterBy:$filterBy, sortBy:$sortBy, search:$search, searchBy:$searchBy, ids:$ids, listingIds:$listingIds) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_LIST_PRODUCT = gql`
  query productList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $filterBy: ProductFilterByInputs, $sortBy: LinkProductByInput, $search: String, $searchBy: String, $ids: [Int], $listingIds:[String]){
    products(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, filterBy:$filterBy, sortBy:$sortBy, search:$search, searchBy:$searchBy, ids:$ids, listingIds:$listingIds){
      edges{
        node{
          ...product
        }
      }
    }
  }
  ${product}
`
export const QUERY_GET_PRODUCT_LISTING = gql`
  query productListing($productId: Int){
    GetProductListings(productId: $productId){
      masterVariantId
      masterVariantSKU
      options{
        type
        option
      }
      variants{
        id
        mongoId
        productName
        storeName
        storeSku
        masterProductId
        isPromotion
        actionAvailabilities{
          name
          group{
            action
            name
          }
        }
        listingAccount
        listingId
        liveLink
        price
        quantity
        status{
          label
          name
          count
        }
        channelId
        channelColor
        channelImage
        channelInitials
        channelName
        salePrice{
          price
          startAt
          endAt
        }
      }
    }
  }
`
export const QUERY_GET_VARIANT_PRODUCT_LISTING = gql`
  query variantProductListing ($limit: Int, $offset: Int, $searchBy: String, $search: Any){
    GetVariantProductListings(limit:$limit, offset:$offset, searchBy:$searchBy, search:$search){
      id
      masterVariantId
      variantName
      storeSku
      storeName
      isPromotion
      options{
        type
        option
      }
      liveLink
      listingId
      listingAccount
      channelId
      channelName
      channelColor
      channelImage
      channelInitials
      navbarId
      masterProductId
      price
      quantity
      status {
        name
        label
      }
      actionAvailabilities {
        name
        group {
          action
          name
        }
      }
      salePrice{
        price
        startAt
        endAt
      }
    }
  }
`
export const MUTATION_ACTION_LINK_LISTING = gql`
  mutation linkListingAction ($input: ActionListingInput!) {
    ActionLinkListing(input:$input){
      message
    }
  }
`
export const QUERY_GET_PRODUCT_BUNDLE = gql`
  query productBundles ($productId: Int) {
    GetProductBundles(productId: $productId) {
      masterVariantId
      masterVariantSKU
      bundleId
      createdAt
      variants {
        id
        masterProductId
        productName
        childSku
        options {
          type
          option
        }
        bundleQuantity
        availableQuantity
        quantityOnHand
        imageUrl
      }
    }
  }
`
export const QUERY_GET_BUNDLE = gql`
  query bundle ($id: Int!) {
    bundle(id: $id) {
      bundleId
      name
      category
      brand
      imageUrl
      description
      kind
      sellingPrice
      masterVariant {
        variantName
        masterVariantId
        productId
        masterProductId
        imageUrl
        sku
        weight
        height
        width
        length
        quantity
        salePrice
        price
      }
      bundleVariants {
        variantName
        masterVariantId
        productId
        masterProductId
        imageUrl
        sku
        quantity
        price {
          regular
          total
          amount
          discountType
        }
      }
      createdAt
      updatedAt
      draftId
    }
  }
`
export const QUERY_GET_TOTAL_LISTING = gql`
  query listingsTotal ($filterBy: ProductListingFilterByInputs) {
    listings (filterBy: $filterBy) {
      totalCount
    }
  }
`
export const QUERY_GET_LIST_LISTING = gql`
  query listingsList ($search: String, $searchBy: String, $filterBy: ProductListingFilterByInputs, $afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $page: Int, $sortBy: LinkProductListingByInput) {
    listings (search: $search, searchBy: $searchBy, filterBy: $filterBy, after: $afterCursor, before: $beforeCursor, first: $first, last: $last, page: $page, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          listingId
          name
          store {
            storeId
            storeName
            channelId
            channelName
            channelImage
            channelInitials
            channelColor
          }
          masterProductID
          liveLink
          imageUrl
          variants {
            id
            sku
            confirmed
            masterVariantId
            masterVariantName
            masterVariantSku
            masterLabelSku
            storeLabelSku
            quantity
            price
            options {
              type
              option
            }
            salePrice {
              price
              startAt
              endAt
            }
            status {
              label
              name
            }
          }
          actionAvailabilities {
            name
            group {
              action
              name
            }
          }
        }
      }
    }
  }
`
export const QUERY_GET_LIST_LISTING_WITH_PROMOTION = gql`
  query listingsListPromo ($search: String, $searchBy: String, $filterBy: ProductListingFilterByInputs, $afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $page: Int, $sortBy: LinkProductListingByInput) {
    listings (search: $search, searchBy: $searchBy, filterBy: $filterBy, after: $afterCursor, before: $beforeCursor, first: $first, last: $last, page: $page, sortBy: $sortBy) {
      edges {
        cursor
        node {
          id
          variants {
            id
            isPromotion
          }
        }
      }
    }
  }
`
export const QUERY_GET_PAGE_LISTING = gql`
  query listingsPage ($search: String, $searchBy: String, $filterBy: ProductListingFilterByInputs, $afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $page: Int, $sortBy: LinkProductListingByInput) {
    listings (search: $search, searchBy: $searchBy, filterBy: $filterBy, after: $afterCursor, before: $beforeCursor, first: $first, last: $last, page: $page, sortBy: $sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_LIST_STORES = gql`
  query stores($filterBy: ChannelsFilterByInput, $search: Int, $searchBy: String) {
    Channels(filterBy: $filterBy, search:$search, searchBy:$searchBy) {
      channelInitials
      channelColor
      channelImage
      channelName
      channelId
      totalStores
      editListing
      navbarId
      Stores {
        id
        storeName
      }
      availableAPI
    }
  }
`
export const MUTATION_ACTION_ACTIVE_INACTIVE = gql`
  mutation actionActiveInactive($input: InputActionActiveInactivePayload!) {
    actionActiveInactive(input: $input) {
      message
      successItems
      failedItems
      unprocessItems
    }
  }
`
export const MUTATION_ACTION_CONFIRM_LINKING = gql`
  mutation actionConfirmLinking($input: InputActionConfirmLinkingPayload!) {
    actionConfirmLinking(input: $input) {
      message
      successItems
      failedItems
      unprocessItems
    }
  }
`
export const MUTATION_ACTION_MANUAL_LINKING = gql`
  mutation actionManualLinking($input: InputActionManualLinkingPayload!) {
    actionManualLinking(input: $input) {
      message
      errors {
        message
        variantIndex
      }
    }
  }
`
export const MUTATION_DELETE_LISTING = gql`
  mutation deleteListings($input: deleteListingsInput!) {
    deleteListings(input: $input) {
      message
      successItems
      failedItems
    }
  }
`
export const QUERY_GET_SELECTED_ALL_VARIANT_CLIENT = gql`
  {
    selectAllVariant @client
  }
`
export const QUERY_GET_SELECTED_VARIANTS_CLIENT = gql`
  {
    selectedVariants @client
  }
`
export const QUERY_GET_SELECTED_ITEM_FILTERS_CLIENT = gql`
  {
    selectFilterVariants @client
  }
`
export const QUERY_GET_VARIANT_FILTER = gql`
  query variantFilter($type: String!) {
    variantsFilter(type: $type) {
      count
      downloadUrl
      name
      value
    }
  }
`
export const QUERY_GET_CHECK_UPDATE_ITEM = gql`
  query itemCheckUpdate($updatedAfter: String) {
    variants(updatedAfter:$updatedAfter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      updateFe
      edges {
        node {
          availableQuantity
          reservedQuantity
          totalQuantity
          id
          profileId
          name
          itemId
          itemName
          sku
          mainImageUrl
          warehouseSpaces {
            quantities {
              accounts {
                id
                channel {
                  id
                  name
                  icon
                  initials
                  color
                }
              }
              available
              reserved
              total
            }
            quantity
            warehouse {
              id
              name
              code 
              default
            }
          }
          config
          bundle {
            bundleVariants {
              main
              unit
              variant {
                id
                masterCatalog {
                  name
                }
                sku
                mainImageUrl
                totalQuantity
              }
            }
          }
          masterCatalog {
            name
            price
          }
          listings {
            id
            channel {
              id
              name
              icon
              initials
              color
            }
            name
            sku
            description
            brand
            price
            active
            confirmed
            syncing
            lastLog
            url
            status
            unconfirmedStatus
            qcStatus {
              message {
                bodies {
                  hasPassed
                  message
                }
                headers
              }
              status
            }
            liveLink
            promotion
            currentSalePrice
            accountId
            account {
              id
              name
              channel {
                id
                name
                icon
                initials
                color
              }
            }
            consignmentQuantity
            channelCategory {
              id
              name
              tree
            }
            stockAllocations {
              id
              startAt
              endAt
              quantity
              reservedQuantity
            }
          }
        }
      }
    }
  }
`
export const QUERY_GET_LIST_ITEMS = gql`
  query itemList($cursor: String, $first: Int, $search: String, $filterBy: VariantFilterByInputs) {
    variants(first:$first, after:$cursor, search:$search, filterBy:$filterBy) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      updateFe
      edges {
        node {
          availableQuantity
          reservedQuantity
          totalQuantity
          id
          profileId
          name
          itemId
          itemName
          sku
          mainImageUrl
          warehouseSpaces {
            quantities {
              accounts {
                id
                channel {
                  id
                  name
                  icon
                  initials
                  color
                }
              }
              available
              reserved
              total
            }
            quantity
            warehouse {
              id
              name
              code 
              default
            }
          }
          config
          bundle {
            bundleVariants {
              main
              unit
              variant {
                id
                masterCatalog {
                  name
                }
                sku
                mainImageUrl
                totalQuantity
              }
            }
          }
          masterCatalog {
            name
            price
          }
          listings {
            id
            channel {
              id
              name
              icon
              initials
              color
            }
            name
            sku
            description
            brand
            price
            active
            confirmed
            syncing
            lastLog
            url
            status
            unconfirmedStatus
            qcStatus {
              message {
                bodies {
                  hasPassed
                  message
                }
                headers
              }
              status
            }
            liveLink
            promotion
            currentSalePrice
            accountId
            account {
              id
              name
              channel {
                id
                name
                icon
                initials
                color
              }
            }
            consignmentQuantity
            channelCategory {
              id
              name
              tree
            }
            stockAllocations {
              id
              startAt
              endAt
              quantity
              reservedQuantity
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_ITEM = gql`
  query item($id: Int!) {
    item (id: $id) {
      id
      name
      images
      variants {
        price
        variantType
        totalQuantity
        reservedQuantity
        availableQuantity
        masterCatalog {
          name
        }
      }
    }
  }
`
export const QUERY_GET_VARIANT = gql`
  query variant($id: Int!) {
    variant(id: $id) {
      availableQuantity
      reservedQuantity
      totalQuantity
      id
      name
      sku
      itemId
      itemName
      profileId
      mainImageUrl
      warehouseSpaces {
        quantities {
          accounts {
            id
            channel {
              id
              name
              icon
              initials
              color
            }
          }
          available
          reserved
          total
        }
        quantity
        warehouse {
          id
          name
          code 
          default
        }
      }
      config
      bundle {
        bundleVariants {
          main
          unit
          variant {
            id
            masterCatalog {
              name
            }
            sku
            mainImageUrl
            totalQuantity
          }
        }
      }
      masterCatalog {
        name
        price
      }
      listings {
        id
        channel {
          id
          name
          icon
          initials
          color
        }
        name
        sku
        description
        brand
        price
        active
        confirmed
        syncing
        lastLog
        url
        status
        unconfirmedStatus
        qcStatus {
          message {
            bodies {
              hasPassed
              message
            }
            headers
          }
          status
        }
        liveLink
        promotion
        currentSalePrice
        accountId
        account {
          id
          name
          channel {
            id
            name
            icon
            initials
            color
          }
        }
        consignmentQuantity
        channelCategory {
          id
          name
          tree
        }
        stockAllocations {
          id
          startAt
          endAt
          quantity
          reservedQuantity
        }
      }
    }
  }
`
export const QUERY_GET_LIST_VARIANT_STOCKS = gql`
  query variantStockList($variantId: Int!) {
    stockHistories(variantId: $variantId) {
      createdAt,
      createdBy,
      quantityIn,
      quantityOut,
      newQuantity
    }
  }
`
export const QUERY_GET_LIST_VARIANT_ACTIVITIES = gql`
  query variantActivityList($variantId: Int!, $startDate: String!, $endDate: String!, $dashboardUserEmail: String, $actionType: String) {
    variantActivities(variantId: $variantId, startDate: $startDate, endDate: $endDate, dashboardUserEmail: $dashboardUserEmail, actionType: $actionType) {
      edges {
        node {
          date
          user
          actionType
          details {
            action
            newValue
            oldValue
            columnName
            channelName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
export const QUERY_GET_LIST_VARIANT_ACTIVITY_ACTION_TYPES = gql`
  query variantActivityActionTypeList{
    variantActivityActionTypes    
  }
`
export const QUERY_GET_LIST_LINKTO_VARIANTS = gql`
  query variantLinkToList($cursor: String, $first: Int, $search: String!, $noIds: [String!], $noAccountIds: [String!]) {
    variants(first:$first, after:$cursor, search: $search, noIds: $noIds, noAccountIds: $noAccountIds) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      updateFe
      edges {
        node {
          id
          name
          sku
          mainImageUrl
        }
      }
    }
  }
` 
export const QUERY_GET_LIST_SEARCH_VARIANTS = gql`
  query variantSearchList($cursor: String, $first: Int, $search: String!) {
    variants(first:$first, after:$cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      updateFe
      edges {
        node {
          id
          name
          sku
          mainImageUrl
        }
      }
    }
  }
` 
export const QUERY_GET_LIST_MOVETO_ITEMS = gql`
  query itemMoveToList($cursor: String, $first: Int, $search: String!, $noIds: [String!]) {
    items(first:$first, after:$cursor, search: $search, noIds: $noIds) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      updateFe
      edges {
        node {
          id
          images
          name
          variants {
            sku
          }
          optionTypes {
            id
            name
            options {
              id
              name
            }
          }
        }
      }
    }
  }
` 
export const _QUERY_GET_LIST_MASTER_CATEGORIES = gql`
  query masterCategoriesList {
    itemMasterCategories 
  }
`
export const QUERY_GET_LIST_TAGS = gql`
  query MasterTags($search: String) {
    MasterTags(search: $search) {
      id
      name
    }
  }
` 
export const QUERY_GET_MAPPING_CATEGORY_ITEM = gql`
  query itemCategoryMapping($masterCategoryName: String!) {
    itemCategoryMapping(masterCategoryName: $masterCategoryName) {
      id
      masterCategoryName
      details {
        accountId
        channelCategoryId
        channelCategoryNames
        id
      }
    }
  }
`
export const QUERY_GET_VARIANT_PRODUCTS = gql`
  query GetVariantProducts($limit: Int, $offset: Int, $search: Any, $searchBy: String, $warehouseIds: [Int], $ids: [Int], $isBundle: Boolean) {
    GetVariantProducts(limit: $limit, offset: $offset, search: $search, searchBy: $searchBy, warehouseIds: $warehouseIds, ids: $ids, isBundle: $isBundle) {
      id
      productId
      masterProductId
      variantName
      productName
      sku
      barcode
      imageUrl
      weight
      height
      width
      length
      regularPrice
      salePrice
      quantityOnHand
      reservedQuantity
      availableQuantity
      options {
        type
        option
      }
      product {
        id
        name
        category
        brand
        imageUrl
        description
      }
    }
  }
`
export const MUTATION_ACTION_BUNDLE_PRODUCT = gql`
  mutation actionBundleProduct($input: InputActionBundleProduct!) {
    actionBundleProduct(input: $input) {
      message
      errors {
        main
        message
        masterVariantId
      }
    }
  }
`
export const SUBSCRIPTION_ITEM_UPDATE = gql`
  subscription productUpdated($profileId: String!) {
    productUpdated(profileId: $profileId){
      profileId
      productId
      previousStatus
      previousStatuses
      currentStatus
      currentStatuses
      eventType
    }
  }
`

export const QUERY_GET_LISTING_PRODUCT = gql`
  query contentListing($accountIds: [Int!], $productIds: [Int], $listingIds: [inputContentListings], $sortBy: contentListingSortBy) {
    contentListing(accountIds: $accountIds, productIds: $productIds, listingIds: $listingIds, sortBy:$sortBy) {
      id
      listingId
      productId
      name
      imageUrl
      description
      brand
      category
      listing {
        id
        accountId
        channelId
        localId
        category {
          labelArr
          valueArr
          value
        }
        custom
      }
      variants {
        id
        variantId
        sku
        imageUrl
        weight
        height
        width
        length
        price
        salePrice
        options {
          type
          option
        }
        listing {
          id
          accountId
          custom
        }
      }
    }
  }
`
export const QUERY_GET_LISTING_HEADER = gql`
  query listingHeader($accountIds: [Int!]) {
    listingHeader(accountIds: $accountIds) {
      key
      name
      icon
      fieldType
      validations {
        required
      }
      typeData
      condition
      value
    }
  }
`
export const QUERY_GET_LISTING_CATEGORIES = gql`
  query listingCategories($accountId: Int!) {
    listingCategories(accountId: $accountId) {
      id
      name
      lowest
      child_categories
    }
  }
`
export const QUERY_GET_LISTING_CUSTOM_HEADER = gql`
  query listingCustomHeader($accountId: Int!, $categoryLocalId: String!, $productId: Int, $listingId: Int) {
    listingCustomHeader(accountId: $accountId, categoryLocalId: $categoryLocalId, productId: $productId, listingId: $listingId) {
      key
      name
      fieldType
      validations {
        required
        min
        max
      }
      schema
      options {
        value
        label
        localId
        option {
          value
          label
          localId
        }
      }
      condition
      typeData
      groupBy
      containOfType
    }
  }
`
export const QUERY_GET_LISTING_BRANDS = gql`
  query listingBrands($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $accountId: Int!, $categoryLocalId: String!) {
    listingBrands(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, accountId:$accountId, categoryLocalId:$categoryLocalId) {
      edges {
        node {
          localId
          label
          value
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`
export const SUBSCRIPTION_LISTING_UPDATE = gql`
  subscription listingUpdated($profileId: String!) {
    listingUpdated(profileId: $profileId){
      profileId
      channelId
      listingId
      previousStatus
      previousStatuses
      currentStatus
      currentStatuses
      eventType
    }
  }
`

/* Sales Orders */
export const QUERY_GET_TOTAL_SALES_ORDER = gql`
  query orderList($filterBy: OrderFilterByInputs, $startDate: String, $endDate: String) {
    orders(filterBy: $filterBy, startDate: $startDate, endDate: $endDate) {
      totalCount
    }
  }
` 

const actionAvailability = gql`
  fragment actionAvailability on ActionAvailability {
    name
    group {
      name
    }
    isDisable
  }
`

const itemline = gql`
  fragment itemline on OrderItemLine {
    id
    sku
    consignment
    shippingProvider
    shippingProviderType
    deliveryShippingProvider
    deliveryShippingProviderType
    name
    variantName
    image
    quantity
    quantityPicked
    quantityPacked
    quantityReturned
    quantityShipped
    price
    salePrice
    notes
    status {
      name
      actionAvailabilities {
        ...actionAvailability
      }
    }
    slaConfirm
    slaShipping
    warehouseId
    warehouseName
    itemImageUrl
    tooltipStatus
    fulfillmentItemLine {
      id
      fulfillmentId
      fulfillment {
        id
        trackingNumber
        trackingCompany
        deliveryTrackingCompany
        bookingCode
      }
    }
  }
  ${actionAvailability}
`
const orderinfo = gql`
  fragment orderinfo on Order {
    id
    customerName
    totalPrice
    grandTotal
    localName
    localId
    orderedAt
    printed
    accountName
    note
    dropship
    dropshipper {
      name
    }
  }
`
const channelinfo = gql`
  fragment channelinfo on Order {
    channelId
    channelGroupId
    channelName
    channelInitials
    channelColor
    channelImage
  }
`
const addressinfo = gql`
  fragment addressinfo on Order {
    address {
      name
      address1
      address2
      subDistrict
      district
      city
      country
      postalCode
      coordinate
      phone
    }
    dropshipper {
      name
    }
  }
`
export const QUERY_GET_PAGE_SALES_ORDER = gql`
  query orderPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: OrderFilterByInputs, $sortBy: LinkOrderByInput, $startDate: String, $endDate: String) {
    orders(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_LIST_SALES_ORDER = gql`
  query orderList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: OrderFilterByInputs, $sortBy: LinkOrderByInput, $startDate: String, $endDate: String) {
    orders(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          ...orderinfo
          ...channelinfo
          itemLines {
            ...itemline
            orderItemLineBundles{
              id
              imageUrl
              name
              sku
              totalQuantity
            }
          }
          packed
          picked
          invoices {
            id
          }
        }
      }
    }
  }
  ${channelinfo}
  ${orderinfo}
  ${itemline}
`
export const QUERY_GET_TOTAL_DETAIL_SALES_ORDER = gql`
  query orderDetail($id: Int!) {
    order(id: $id) {
      id
      picklists {
        id
      }
      packages {
        id
      }
      fulfillments {
        id
      }
      invoices {
        id
      }
    }
  }
` 
export const QUERY_GET_DETAIL_SALES_ORDER = gql`
  query orderDetail($id: Int!) {
    order(id: $id) {
      ...orderinfo
      ...channelinfo
      itemLines {
        ...itemline
        orderItemLineBundles{
          id
          imageUrl
          name
          sku
          unit
          totalQuantity
        }
      }
      payment {
        paymentMethod
        commission
        serviceFee
      }
      cod
      ...addressinfo
      customer {
        email
      }
      subtotal
      voucherSeller
      voucherCode
      rebateToSeller
      shippingPrice
      cashless
      note
      invoices {
        id
      }
      staffName
    }
  }
  ${channelinfo}
  ${itemline}
  ${orderinfo}
  ${addressinfo}
`

export const QUERY_GET_DETAIL_SALES_ORDER_AT_ACTIVITIES = gql`
  query orderDetailatActivities($orderId: Int!) {
    orderActivities(orderId: $orderId) {
      id
      actionAt
      message
      username
      source
    }
  }
`

export const QUERY_GET_LIST_SALES_ORDER_AT_PRINT_SHIPPING_LABELS = gql`
  query orderListPrintShippingLabel($activityId: Int!) {
    printOrders(activityId: $activityId) {
      edges {
        node {
          id
          accountName
          localId
          localName
          orderedAt
          note
          ...channelinfo
          dropship
          dropshipper {
            name
            phone
          }
          address {
            name
            address1
            address2
            subDistrict
            district
            city
            province
            country
            phone
            postalCode
          }
          itemLines{
            id
            name
            variantName
            quantity
            notes
            sku
            weight
            bins {
              binName
              quantity
            }
            orderItemLineBundles{
              id
              name
              sku
              totalQuantity
              bins {
                binName
                quantity
              }
            }
            status{
              actionAvailabilities{
                ...actionAvailability
              }
            }
            fulfillmentItemLine {
              fulfillment {
                trackingNumber
                bookingCode
              }
            }
            shippingProviderType
            slaShipping
          }
          fulfillments{
            trackingLogo
            trackingCompany
            logisticDestinationCode
          }
          cashless
          cod
        }
      }
    }
  }
  ${channelinfo}
  ${actionAvailability}
`

export const QUERY_GET_LIST_SALES_ORDER_AT_PRINT_SALES_ORDERS = gql`
  query orderListPrintSalesOrders($ids: [String!]) {
    orders(ids: $ids) {
      edges {
        node {
          id
          accountName
          localId
          localName
          status{
            name
          }
          address {
            name
            address1
            address2
            subDistrict
            district
            city
            province
            country
            phone
            postalCode
          }
          orderedAt
          channelName
          warehouse{
            name
          }
          itemLines{
            id
            name
            price
            totalPrice
            quantity
            sku
            orderItemLineBundles{
              id
              name
              sku
            }
          }
          subtotal
          voucherSeller
          discShippingSeller
          rebateToSeller
          grandTotal
        }
      }
    }
  }
`

export const QUERY_GET_LIST_SALES_ORDER_AT_PRINT_INVOICES = gql` 
  query orderListPrintInvoice($ids: [String!]) {
    orders(ids: $ids) {
      edges {
        node {
          id
          localId
          localName
          address {
            name
            address1
            address2
            subDistrict
            district
            city
            province
            country
            phone
            postalCode
          }
          warehouse {
            name
          }
          cashless
          invoices {
            id
            invoiceDate
            dueDate
            status
            subtotal
            voucherSeller
            rebate
            shipping
            shippingFeeDiff
            fulfillmentFee
            fee
            total
            balanceDue
            term
            vatIn
            vatOut
            itemLines {
              name
              sku
              quantity
              itemPrice
              taxPrice
              taxRate
              tax
              total
              itemLineBundle{
                name
                sku
                totalQuantity
              }
            }
            payment {
              amountReceived
              payment {
                createdAt
              }
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_PICKLIST_AT_PRINT_PICKLIST = gql`
  query picklistPrint($id: Int!) {
    picklist(id: $id) {
      id
      refId
      picker {
        id
        name
      }
      picklistItemLines {
        id
        name
        sku
        picklistItemLineBundles {
          id
          name
          sku
          qtyToPick
          orderItemLine {
            id
            variantName
            bins {
              binName
              quantityToPick
            }
          }
        }
        orderItemLine {
          id
          customerName
          variantName
          shippingProvider
          order {
            id
            localId
            localName
            ...channelinfo
          }
          fulfillmentItemLine {
            id
            fulfillment {
              id
              trackingCompany
            }
          }
          bins {
            binName
            quantityToPick
          }
        }
        orderId
        qtyToPick
      }
    }
  }
  ${channelinfo}
`

export const QUERY_GET_LIST_PACKAGES_AT_PRINT_PACKAGES = gql`
  query packageListPrint($ids: [String!]) {
    packages(ids: $ids) {
      edges {
        node {
          id
          refId
          order {
            packed
          }
          creator {
            id
            name
          }
          warehouse {
            id
            name
          }
          createdAt
          order {
            id
            channelName
            accountName
            packed
          }
          packageItemLines {
            id
            qtyOrdered
            qtyPacked
            qtyToPacked
            name
            sku
            packageItemLineBundles {
              id
              name
              sku
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_DETAIL_SALES_ORDER_AT_PICKLIST = gql`
  query orderDetailatPicklist($id: Int!) {
    order(id: $id) {
      id
      picklists {
        id
        refId
        createdAt
        status {
          id
          name
        }
        warehouse {
          name
        }
        picker {
          id
          name
        }
        orderPicklist {
          id
          orderId
        }
        picklistItemLines {
          id
          name
          sku
          orderId
          itemImageUrl
          qtyOrdered
          qtyPicked
          qtyToPick
          picklistItemLineBundles {
            id
            name
            sku
            orderId
            qtyOrdered
            qtyPicked
            qtyToPick
            itemImageUrl
          }
        }
        actionButton
      }
    }
  }
`
export const QUERY_GET_DETAIL_SALES_ORDER_AT_PACKAGE = gql`
  query orderDetailatPackage($id: Int!) {
    order(id: $id) {
      id
      packages {
        id
        refId
        orderId
        createdAt
        creator {
          id
          name
        }
        warehouse {
          name
        }
      }
    }
  }
`
export const QUERY_GET_TOTAL_SHIPMENT = gql`
  query fulfillmentList($filterBy: OrderFulfillmentFilterByInputs) {
    fulfillments(filterBy:$filterBy) {
      totalCount
    }
  }
`
export const QUERY_GET_PAGE_SHIPMENT = gql`
  query fulfillmentPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: OrderFulfillmentFilterByInputs, $sortBy: LinkOrderFulfillmentByInput) {
    fulfillments(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_LIST_SHIPMENT = gql`
  query fulfillmentList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: OrderFulfillmentFilterByInputs, $sortBy: LinkOrderFulfillmentByInput) {
    fulfillments(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      edges {
        node {
          id
          orderId
          order {
            id
            customerName
            ...channelinfo
            itemLines {
              id
              status {
                name
                actionAvailabilities {
                  ...actionAvailability
                }
              }
              fulfillmentItemLine {
                id
                fulfillmentId
                fulfillment {
                  id
                  trackingNumber
                  trackingCompany
                  bookingCode
                }
              }
            }
          }
          status {
            name
            actionAvailabilities {
              ...actionAvailability
            }
          }
          trackingCompany
          trackingNumber
          bookingCode
          printed
          createdAt
        }
      }
    }
  }
  ${channelinfo}
  ${actionAvailability}
`
export const QUERY_GET_DETAIL_SHIPMENT = gql`
  query fulfillmentDetail($id: Int!) {
    fulfillment(id: $id) {
      id
      orderId
      createdAt
      trackingCompany
      trackingNumber
      order {
        id
        createdAt
        customerName
        customer {
          email
        }
        ...channelinfo
        accountName
        warehouse {
          name
        }
        localName
        localId
        ...addressinfo
        payment {
          paymentMethod
          commission
          serviceFee
        }
        cod
        cashless
        dropshipper {
          name
        }
        itemLines {
          id
          orderId
          warehouseName
          itemImageUrl
          quantity
          quantityShipped
          name
          sku
          status {
            name
          }
          fulfillmentItemLine {
            id
            fulfillmentId
            fulfillment {
              id
              trackingNumber
              trackingCompany
              bookingCode
            }
          }
          orderItemLineBundles {
            id
            name
            sku
            imageUrl
            totalQuantity
          }
        }
      }
      status {
        name
        actionAvailabilities {
          ...actionAvailability
        }
      }
    }
  }
  ${channelinfo}
  ${addressinfo}
  ${actionAvailability}
`
export const QUERY_GET_DETAIL_SHIPMENT_AT_ACTIVITIES = gql`
  query shipmentDetailatActivities($shipmentId: Int!) {
    shipmentActivities(shipmentId: $shipmentId) {
      id
      actionAt
      message
      username
      source
    }
  }
`

export const QUERY_GET_PAGE_INVOICE = gql`
  query invoicesPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: InvoiceFilterByInputs, $sortBy: LinkInvoiceByInput, $startDate: String, $endDate: String) {
    invoices(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_LIST_INVOICE = gql`
  query invoiceList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: InvoiceFilterByInputs, $sortBy: LinkInvoiceByInput, $startDate: String, $endDate: String) {
    invoices(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          channelID
          status
          createdAt
          updatedAt
          subtotal
          total
          balanceDue
          shipping
          rebate
          voucherSeller
          actionAvailabilities {
            ...actionAvailability
          }
          payment {
            id 
            payment {
              id
              reference
            }
          }
          orderInvoice {
            id
            order {
              id
              channelId
              channelName
              channelImage
              channelColor
              channelInitials
              accountName
              cashless
              customerName
              itemLines {
                id 
                status {
                  name
                }
              }
              warehouse {
                id
                name
              }
            }
          }
          payoutByAPI
        }
      }
    }
  }
  ${actionAvailability}
`

export const QUERY_GET_DETAIL_INVOICE = gql` 
  query invoiceDetail($id: Int!) {
    invoice(id:$id) {
      id
      channelID
      status
      createdAt
      invoiceReference
      subtotal
      shipping
      fee
      rebate
      voucherSeller
      shippingFeeDiff
      fulfillmentFee
      total
      balanceDue
      vatIn
      vatOut
      returnAmount
      payoutByAPI
      actionAvailabilities {
        ...actionAvailability
      }
      payment {
        id
        paymentID
        payment {
          id
        }
      }
      orderInvoice {
        id
        order {
          id
          channelName
          channelImage
          channelColor
          channelInitials
          accountName
          localName
          localId
          customerName
          cashless
          itemLines {
            id
            status {
              name
            }
          }
          warehouse {
            id
            name
          }
        }
      }
      itemLines {
        itemLineID
        itemImageUrl
        quantity
        name
        sku
        itemPrice
        taxPrice
        taxRate
        tax
        total
        notes
        status {
          name
        }
        itemLineBundle {
          id
          name
          sku
          imageUrl
          totalQuantity
          orderItemLineId
        }
      }
      invoicePayout {
        accountName
        description
        amount
      }
    }
  }
  ${actionAvailability}
`

export const QUERY_GET_DETAIL_INVOICE_AT_SALESORDERS = gql` 
  query orderDetailatInvoice($id: Int!) {
    invoice(id: $id) {
      id
      orderInvoice {
        id
        order {
          id
          createdAt
          customerName
          itemLines {
            id
            status {
              name
            }
          }
          warehouse {
            id
            name
          }
        }
      }
    }
  }
`

export const QUERY_GET_DETAIL_INVOICE_AT_PAYMENT = gql` 
  query paymentDetailatInvoice($id: Int!) {
    invoice(id: $id) {
      id
      status
      paidAt
      payment {
        id
        amountReceived
        payment {
          id
          reference
        }
      }
      payoutByAPI
    }
  }
`

export const QUERY_GET_PAGE_PAYMENT_RECEIVED = gql`
  query paymentReceivedPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PaymentReceivedFilterByInputs, $sortBy: LinkPaymentReceivedByInput, $startDate: String, $endDate: String) {
    paymentReceiveds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_LIST_PAYMENT_RECEIVED = gql` 
  query paymentReceivedList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PaymentReceivedFilterByInputs, $sortBy: LinkPaymentReceivedByInput, $startDate: String, $endDate: String){
    paymentReceiveds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          profileId
          createdBy
          totalInvoices
          amountReceived
          createdAt
        }
      }
    }
  }
`

export const QUERY_GET_DETAIL_PAYMENT_RECEIVED = gql` 
  query paymentReceivedDetail($id: Int!) {
    paymentReceived(id:$id) {
      id
      profileId
      createdBy
      totalInvoices
      amountReceived
      createdAt
    }
  }
`

export const QUERY_GET_DETAIL_PAYMENT_RECEIVED_AT_INVOICES = gql` 
  query paymentReceivedDetailatInvoices($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: InvoiceFilterByInputs, $paymentReceivedId: Int) {
    invoices(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, paymentReceivedId:$paymentReceivedId) {
      edges {
        node {
          id
          total
          paidAt
          orderInvoice {
            id
            order {
              id
              localId
              localName
              accountName
              channelId
              channelName
              channelImage
              channelInitials
              channelColor
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_DETAIL_PAYMENT_RECEIVED_AT_PAGE_INVOICES = gql` 
  query paymentReceivedDetailatInvoices($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: InvoiceFilterByInputs, $paymentReceivedId: Int) {
    invoices(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, paymentReceivedId:$paymentReceivedId) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_POPUP_PAYMENT_RECEIVE = gql` 
  query payoutCheck($invoiceId: Int!, $channelId: Int!) {
    payoutChannel(invoiceId: $invoiceId, channelId: $channelId) {
      success
      triable
      voucherAmount
      serviceFee
      platformRebate
      shipping
      shippingFeeDiff
      fulfillmentFee
      grandTotal
      channelPaymentDate
    }
  }
`

export const QUERY_GET_PAYMENT_RECEIVE_NEW = gql` 
  query payoutCheck($payoutChannelInput: [payoutChannelInputs!]) {
    payoutChannel(payoutChannelInput: $payoutChannelInput) {
      invoiceId
      success
      voucherAmount
      serviceFee
      platformRebate
      shipping
      shippingFeeDiff
      fulfillmentFee
      returnAmount
      grandTotal
      channelPaymentDate
      order{
        channelId
        channelName
        channelColor
        channelImage
        channelInitials
        accountName
        cashless
        subtotal
      }
    }
  }
`

export const QUERY_GET_DETAIL_SALES_ORDER_AT_SHIPMENT = gql`
  query orderDetailatShipment($id: Int!) {
    order(id: $id) {
      id
      fulfillments {
        id
        createdAt
        orderId
        status {
          name
          actionAvailabilities {
            ...actionAvailability
          }
        }
        trackingCompany
        trackingNumber
        bookingCode
        printed
        itemLines {
          id
          orderItemLine {
            id
            warehouseName
          }
        }
      }
    }
  }
  ${actionAvailability}
`
export const QUERY_GET_DETAIL_SALES_ORDER_AT_INVOICE = gql`
  query orderDetailatInvoice($id: Int!) {
    order(id: $id) {
      id
      invoices {
        id
        createdAt
        status
        balanceDue
      }
      
    }
  }
`
export const QUERY_GET_LIST_FILTER_STORES = gql`
  query accountList($onlySalesOrder: Boolean, $onlyPromotion: Boolean) {
    accounts(onlySalesOrder: $onlySalesOrder, onlyPromotion: $onlyPromotion) {
      id
      name
      channel {
        id
        name
        initials
        color
        icon
      }
    }
  }
`
export const QUERY_GET_LIST_FILTER_COURIERS = gql`
  query courierList($isProfileFiltered: Boolean) {
    orderCouriers(isProfileFiltered: $isProfileFiltered) {
      name
      groupFulfillmentType
    }
  }
`
export const QUERY_GET_LIST_FILTER_WAREHOUSES = gql`
  query warehouseList {
    warehouses {
      id
      name
    }
  }
`
export const QUERY_GET_LIST_FILTER_WAREHOUSES_NEW = gql`
  query warehouseV2List {
    warehousesV2 {
      id
      oldId
      name
    }
  }
`
export const QUERY_GET_PAGE_PICKLIST = gql`
  query picklistPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PicklistFilterByInputs, $sortBy: LinkPicklistByInput, $startDate: String, $endDate: String) {
    picklists(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_LIST_PICKLIST = gql`
  query picklistList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PicklistFilterByInputs, $sortBy: LinkPicklistByInput, $startDate: String, $endDate: String) {
    picklists(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          refId
          pickerId
          status {
            id
            name
          }
          picker {
            id
            email
            name
          }
          warehouse {
            id
            name
          }
          createdAt
          updatedAt
          actionAvailabilities {
            ...actionAvailability
          }
          orderPicklist {
            id
            orderId
            order {
              ...orderinfo
              ...channelinfo
              invoices {
                id
              }
              itemLines {
                id
                status {
                  name
                }
                fulfillmentItemLine {
                  id
                  fulfillment {
                    id
                    trackingNumber
                    bookingCode
                    trackingCompany
                  }
                }
              }
            }
          }
          picklistItemLines {
            id
            name
            sku
            orderId
            itemImageUrl
            qtyOrdered
            qtyPicked
            qtyToPick
            picklistItemLineBundles {
              id
              name
              sku
              orderId
              qtyOrdered
              qtyPicked
              qtyToPick
              itemImageUrl
            }
          }
        }
      }
    }
  }
  ${orderinfo}
  ${channelinfo}
  ${actionAvailability}
`
export const QUERY_GET_DETAIL_PICKLIST_AT_SALESORDERS = gql`
  query picklistDetailatSalesOrders($id: Int!) {
    picklist(id: $id) {
      id
      orderPicklist {
        id
        orderId
        order {
          id
          orderedAt
          customerName
          itemLines {
            id
            status {
              name
            }
            warehouseName
          }
        }
      }
    }
  }
`
export const QUERY_GET_DETAIL_PICKLIST_AT_ACTIVITIES = gql`
  query picklistDetailatActivities($picklistId: Int!) {
    picklistActivities(picklistId: $picklistId) {
      id
      actionAt
      message
      username
      source
    }
  }
`
export const QUERY_GET_TOTAL_PACKAGE = gql`
  query packageList{
    packages{
      totalCount
    }
  }
` 
export const QUERY_GET_PAGE_PACKAGE = gql`
  query packagePage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PackageFilterByInputs, $sortBy: LinkPackageByInput) {
    packages(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_LIST_PACKAGE = gql`
  query packageList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PackageFilterByInputs, $sortBy: LinkPackageByInput) {
    packages(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      edges {
        node {
          id
          refId
          orderId
          printed
          order {
            packed
            customerName
            fulfillments {
              trackingCompany
            }
            itemLines {
              shippingProvider
            }
          }
          creator {
            id
            name
          }
          warehouse {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`
export const QUERY_GET_DETAIL_PACKAGE = gql`
  query packageDetail($id: Int!) {
    package(id: $id) {
      id
      refId
      printed
      creator {
        id
        name
      }
      warehouse {
        id
        name
      }
      createdAt
      updatedAt
      order {
        id
        channelName
        accountName
        packed
        customerName
        fulfillments {
          trackingCompany
        }
        itemLines {
          shippingProvider
        }
      }
      packageItemLines {
        id
        qtyOrdered
        qtyPacked
        orderItemLine {
          id
          name
          sku
          itemImageUrl
          warehouseName
        }
        packageItemLineBundles {
          id
          name
          sku
          itemImageUrl
          totalQuantity
        }
      }
    }
  }
`
export const QUERY_GET_DETAIL_PACKAGE_AT_SALESORDERS = gql`
  query packageDetailatSalesOrders($id: Int!) {
    package(id: $id) {
      id
      order {
        id
        orderedAt
        customerName
        itemLines {
          id
          status {
            name
          }
          warehouseName
        }
      }
    }
  }
`
export const QUERY_GET_DETAIL_PACKAGE_AT_ACTIVITIES = gql`
  query packageDetailatActivities($packageId: Int!) {
    packageActivities(packageId: $packageId) {
      id
      actionAt
      message
      username
      source
    }
  }
`
export const QUERY_GET_TOTAL_PICKLIST = gql`
  query pickList($filterBy: PicklistFilterByInputs, $startDate: String, $endDate: String) {
    picklists(filterBy: $filterBy, startDate: $startDate, endDate: $endDate) {
      totalCount
    }
  }
`
export const QUERY_GET_POPUP_PICKLIST = gql`
  query picklistPopup($whId: Int!, $orderId: [Int], $search: String, $searchBy: String) {
    popUpPicklist(whId: $whId, orderId: $orderId, search:$search, searchBy:$searchBy) {
      refId
      orders {
        id
        customerName
        picked
        itemLines {
          id
          consignment
          name
          variantName
          sku
          quantity
          warehouseId
          warehouseName
          itemImageUrl
          barcode
          bins {
            id
            binName
            quantity
          }
          orderItemLineBundles {
            id
            name
            sku
            totalQuantity
            warehouseId
            warehouseName
            imageUrl
            barcode
            bins {
              id
              binName
              quantity
            }
          }
        }
      }
    }
  }
`
export const QUERY_GET_DETAIL_PICKLIST = gql`
  query picklistDetail($id: Int!) {
    picklist(id: $id) {
      id
      refId
      status {
        id
        name
      }
      createdAt
      warehouse {
        id
        name
      }
      picker {
        id
        name
      }
      actionAvailabilities {
        ...actionAvailability
      }
      orderPicklist {
        id
        orderId
        order {
          ...orderinfo
          ...channelinfo
          invoices {
            id
          }
          itemLines {
            id
            status {
              name
            }
            fulfillmentItemLine {
              id
              fulfillment {
                id
                trackingNumber
                bookingCode
                trackingCompany
              }
            }
          }
        }
      }
      picklistItemLines {
        id
        name
        sku
        orderId
        itemImageUrl
        barcode
        orderItemLine {
          id
          order {
            ...orderinfo
            ...channelinfo
            printed
            fulfillments {
              id
              trackingNumber
              bookingCode
              trackingCompany
            }
          }
          status {
            name
          }
          bins {
            id
            binName
            quantity
            quantityToPick
            quantityPicked
          }
        }
        qtyOrdered
        qtyPicked
        qtyToPick
        picklistItemLineBundles {
          id
          barcode
          name
          sku
          orderId
          qtyOrdered
          qtyPicked
          qtyToPick
          itemImageUrl
          totalQuantity
          orderItemLine {
            id
            bins {
              id
              binName
              quantity
              quantityToPick
              quantityPicked
            }
          }
        }
      }
    }
  }
  ${orderinfo}
  ${channelinfo}
  ${actionAvailability}
`

export const QUERY_GET_POPUP_SHIPMENT = gql`
  query popupShipment($orderIds: [String!]) {
    popUpShipment(orderIds: $orderIds) {
      orders {
        id
        customerName
        ...channelinfo
        itemLines {
          id
          name
          variantName
          sku
          quantity
          quantityShipped
          warehouseId
          warehouseName
          itemImageUrl
          shippingProvider
          status {
            name
            actionAvailabilities {
              ...actionAvailability
            }
          }
          orderItemLineBundles {
            id
            name
            sku
            totalQuantity
            quantityShipped
            warehouseId
            warehouseName
            imageUrl
          }
        }
      }
    }
  }
  ${channelinfo}
  ${actionAvailability}
`

export const QUERY_GET_POPUP_CANCEL = gql`
  query popupCancel($orderIds: [String!]) {
    popUpCancel(orderIds: $orderIds) {
      orders {
        id
        customerName
        orderedAt
        cancelReasons {
          value
          name
        }
        ...channelinfo
        itemLines {
          id
          name
          variantName
          itemImageUrl
          sku
          quantity
          consignment
          warehouseName
          status {
            name
          }
          orderItemLineBundles {
            id
            name
            sku
            totalQuantity
            quantityPacked
            warehouseId
            warehouseName
            imageUrl
          }
        }
      }
    }
  }
  ${channelinfo}
`

export const QUERY_GET_POPUP_READY_TO_SHIP = gql`
  query popUpReadyToShip($orderIds: [String!]) {
    popUpReadyToShip(orderIds: $orderIds) {
      orders {
        id
        status {
          name
        }
        customerName
        ...channelinfo
        accountName
        profileId
        itemLines {
          id
          fulfillmentItemLine {
            id
            fulfillmentId
            fulfillment {
              trackingNumber
              trackingCompany
              bookingCode
            }
          }
          status{
            name
            actionAvailabilities{
              ...actionAvailability
            }
          }
        }
        packDetail {
          couriers {
            addresses {
              id
              address
              timeSlots {
                label
                value
                date
              }
            }
            awbFulfillStatus
            awbPrintStatus
            default
            name
          }
          isCourierChangeable
          shipping {
            awb
            type
          }
          shippingType
        }
      }
    }
  }
  ${channelinfo}
  ${actionAvailability}
`

export const MUTATION_CANCEL_ORDER = gql`
  mutation cancelOrder($input: OrderCancelInput!) {
    cancelOrder(input: $input) {
      successOrders
      failedOrders
      status
      message
    }
  }
`

export const MUTATION_CREATE_PICKLIST = gql`
  mutation picklistCreate($input: CreatePicklistInput!) {
    createPicklist(input: $input) {
      message
      picklist {
        id
      }
    }
  }
`
export const MUTATION_CREATE_PRINT_PICKLIST = gql`
  mutation picklistCreatePrint($input: CreatePicklistInput!) {
    createPrintPicklist(input: $input) {
      message
      picklist {
        id
      }
    }
  }
`
export const MUTATION_DELETE_PICKLIST = gql`
  mutation picklistDelete($ids: [String!]) {
    deletePicklist(ids: $ids) {
      success
      successPicklists
      failedPicklists
    }
  }
`
export const MUTATION_UPDATE_PICKLIST = gql`
  mutation picklistUpdate($input: UpdatePicklistInput!) {
    updatePicklist(input: $input) {
      message
      picklist {
        id
      }
    }
  }
`
export const MUTATION_CREATE_PACKAGE = gql`
  mutation packageCreate($input: CreatePackageInput!) {
    createPackage(input: $input) {
      message
    }
  }
`
export const MUTATION_DELETE_PACKAGE = gql`
  mutation packageDelete($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      success
      successPackages
      failedPackages
    }
  }
`
export const MUTATION_CREATE_SHIPMENT = gql`
  mutation shipmentCreate($input: CreateShipmentInput!) {
    createShipment(input: $input) {
      successOrders
      failedOrders
      status
      message
    }
  }
`
export const MUTATION_CREATE_SHIPMENT_RTS = gql`
  mutation createShipmentRTS($input: CreateShipmentRTSInput!) {
    createShipmentRTS(input: $input) {
      successOrders
      failedOrders
      status
      message
    }
  }
`
export const MUTATION_MARK_AS_READY_TO_SHIP = gql`
  mutation markAsReadyToShip($input: MarkAsReadyToShipInput!) {
    markAsReadyToShip(input: $input) {
      successOrders
      failedOrders
      status
      message
      limitSummary
    }
  }
`
export const MUTATION_MARK_AS_DELIVERED = gql` 
  mutation markAsDelivered($input: MarkAsDeliveredInput!) {
    markAsDelivered(input: $input) {
      successOrders
      failedOrders
      status
      message
      limitSummary
    }
  }
`
export const MUTATION_MARK_AS_COMPLETED = gql` 
  mutation markAsCompleted($input: MarkAsCompletedInput!) {
    markAsCompleted(input: $input) {
      successOrders
      failedOrders
      status
      message
    }
  }
`
export const MUTATION_CREATE_RETURN = gql`
  mutation returnCreate($input: CreateReturnInput!) {
    createReturn(input: $input) {
      status
      message
    }
  }
`
export const MUTATION_PRINT_ORDER = gql`
  mutation orderPrint($input: PrintOrderInput!) {
    printOrder(input: $input) {
      documentPath
      failedOrders
      failedSummary
      successOrders
      successSummary
      summary
      status
      message
    }
  }
`
export const MUTATION_PRINT_PICKLIST = gql`
  mutation picklistPrint($input: PrintPicklistInput!) {
    printPicklist(input: $input) {
      message
    }
  }
`
export const MUTATION_MARK_AS_PAID = gql`
  mutation markaspaid($input: MarkAsPaidInput!) {
    markAsPaid(input: $input) {
      success
      errors
    }
  }
`
export const MUTATION_IMPORT_UPDATE_ORDER = gql`
  mutation importUpdateOrder($input: ImportOrderInput!) {
    importUpdateOrder(input: $input) {
      success
      orderId
      message
    }
  }
`
export const MUTATION_UPDATE_INVOICE_STATUS = gql` 
  mutation updateStatusInvoice($input: UpdateInvoiceStatusInput!) {
    updateInvoiceStatus(input: $input) {
      success
      message 
    }
  }
`
export const MUTATION_EDIT_ORDER_NOTE = gql` 
  mutation updateOrderNote($input: UpdateOrderNoteInput!){
    updateOrderNote(input: $input) {
      success
      message
    }
  }
`
export const MUTATION_RECORD_PAYMENT_RECEIVE = gql` 
  mutation recordPaymentReceive($input: CreateFullPaymentReceiveInput!) {
    createFullPaymentReceive(input: $input) {
      success
      message
    }
  }
`
export const MUTATION_GENERATE_PAYMENT_RECEIVE = gql` 
  mutation generatePaymentReceive($input: CreateGeneratePaymentReceiveInput) {
    createGeneratePaymentReceive(input: $input) {
      success
      message
    }
  }
`
export const MUTATION_UPLOAD_PAYMENT_RECEIVE = gql` 
  mutation uploadPaymentReceive($input: CreateExcelPaymentReceiveInput) {
    createExcelPaymentReceive(input: $input) {
      success
      message
    }
  }
`
export const MUTATION_EXPORT_SALES_ORDER = gql` 
 mutation exportSalesOrder($input: ExportOrderInput!) {
  exportOrder(input: $input) {
    success
    message
  }
 }
`
export const MUTATION_EXPORT_INVOICE = gql` 
 mutation exportInvoice($input: ExportInvoiceInput!) {
  exportInvoice(input: $input) {
    success
    message
  }
 }
`
export const MUTATION_EXPORT_PAYMENT_RECEIVED = gql` 
  mutation exportPaymentReceive($input: ExportPaymentReceiveInput!) {
    exportPaymentReceive(input: $input) {
      success
      message
    }
  }
`
export const MUTATION_IMPORT_UPDATE_INVOICE = gql`
  mutation importUpdateInvoice($input: ImportInvoiceInput!) {
    importUpdateInvoice(input: $input) {
      success
      invoiceId
      message
    }
  }
`
export const SUBSCRIPTION_ORDER_UPDATE = gql`
  subscription orderUpdated($profileId: String!) {
    orderUpdated(profileId: $profileId){
      profileId
      orderId
      previousStatus
      previousStatuses
      currentStatus
      currentStatuses
      eventType
    }
  }
`

export const SUBSCRIPTION_INVOICE_UPDATE = gql`
  subscription invoiceUpdated($profileId: String!) {
    invoiceUpdated(profileId: $profileId){
      profileId
      invoiceId
      previousStatus
      previousStatuses
      currentStatus
      currentStatuses
      eventType
    }
  }
`

/* RETURN */
export const QUERY_GET_TOTAL_OPEN_RETURNS = gql`
  query returnTotalOpen {
    returns(filterBy: {status: ["Open", "partial_received"]}) {
      totalCount
    }
  }
`
export const QUERY_GET_SELECTED_RETURN_FILTERS_CLIENT = gql`
  {
    selectReturnFilters @client
  }
`
export const QUERY_GET_LIST_RETURN_FILTERS = gql`
  query returnFilterList($type: String!){
    returnsFilter (type: $type) {
      name
      value
    }
  }
`
export const QUERY_GET_LIST_SALES_RETURN = gql`
  query salesReturnList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: ReturnOrderFilterByInputs, $sortBy: LinkReturnOrderByInput) {
    returnOrders(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy filterBy:$filterBy, sortBy:$sortBy){
      edges {
        node {
          id
          status
          receivedStatus
          returnActivities {
            createdBy
            approvedBy
          }
          warehouse {
            name
          }
          order {
            id
            localName
            localId
            customerName
          }
          updatedAt
        }
      }
    }
  }
`
export const QUERY_GET_PAGE_SALES_RETURN = gql`
  query salesReturnPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: ReturnOrderFilterByInputs, $sortBy: LinkReturnOrderByInput) {
    returnOrders(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy filterBy:$filterBy, sortBy:$sortBy){
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_DETAIL_SALES_RETURN = gql`
  query salesReturnDetail($id: Int!) {
    return(id: $id) {
      id
      status
      warehouse {
        name
      }
      order {
        id
        localName
        localId
        channelName
        channelInitials
        channelColor
        channelImage
        customerName
        accountName
        cashless
      }
      orderitemLines {
        id
        sku
        consignment
        name
        variantName
        itemImageUrl
        quantity
        quantityReturned
        price
        salePrice
        taxPrice
        taxRate
        status {
          name
        }
        orderItemLineBundles{
          id
          imageUrl
          name
          sku
          totalQuantity
        }
      }
      returnActivities {
        createdBy
        approvedBy
      }
      invoice {
        id
        invoiceReference
      }
      createdAt
    }
  }
`
export const QUERY_GET_DETAIL_SALES_RETURN_AT_INVOICE = gql`
  query salesReturnDetailAtInvoice($id: Int!) {
    return(id: $id) {
      id
      invoice {
        id
        status
        balanceDue
        createdAt
      }
    }
  }
`
export const MUTATION_UPDATE_RETURN_STATUS = gql`
  mutation updateStatusReturn($input: updateStatusReturnInput!) {
    updateStatusReturn(input: $input) {
      status
      message
    }
  }
`
export const MUTATION_COMPLETE_RETURN = gql`
  mutation returnComplete($input: UpdateReturnInput!) {
    updateReturn(input: $input) {
      returns {
        id
        orderId
        accountId
        status
      }
    }
  }
`

/* ANALYTIC */
export const QUERY_GET_LIST_ANALYTICS = gql`
  query analyticList {
    analytics {
      sections {
        name
        pages {
          name
          url
        }
      }
    }
  }
`

/* CATEGORY */
export const QUERY_GET_CATEGORY = gql`
  query channelCategories($accountId: Int!, $parentId: Int) {
    channelCategories(accountId: $accountId, parentId: $parentId) {
      id
      localId
      name
      parentCategoryId
    }
  }
`

/* WAREHOUSE */
export const QUERY_GET_WAREHOUSE = gql`
  query warehouse($id: Int!) {
    warehouse(id: $id) {
      id
      address {
        id
        address
        email
        lat
        lon
        phone
        postalCode
        subDistrictID
        subDistrict {
          id
          name
          districtId
          district {
            id
            name
            cityId
            city {
              id
              name
              provinceId
              province {
                id
                name
                countryId
                country {
                  id
                  name
                }
              }
            }
          }
        }
      }
      isDeletable
      code
      default
      status
      name
      profileID
      createdAt
      updatedAt
      isPos
    }
  }
`
export const QUERY_GET_TOTAL_WAREHOUSES = gql`
  query warehouseList {
    warehouses{
      id
    }
  }
`
export const MUTATION_SET_DEFAULT_WAREHOUSE = gql`
  mutation warehouseDefaultSet($input: SetDefaultWarehouseInput!) {
    setDefaultWarehouse(input: $input) {
      warehouse {
        id
        address {
          id
          address
          email
          lat
          lon
          phone
          postalCode
          subDistrictID
          subDistrict {
            id
            name
            districtId
            district {
              id
              name
              cityId
              city {
                id
                name
                provinceId
                province {
                  id
                  name
                  countryId
                  country {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        isDeletable
        code
        default
        status
        name
        profileID
        updatedAt
        isPos
      }
    }
  }
`
export const MUTATION_SET_STATUS_WAREHOUSE = gql`
  mutation warehouseStatusSet($input: SetStatusWarehouseInput!) {
    setStatusWarehouse(input: $input) {
      warehouse {
        id
        address {
          id
          address
          email
          lat
          lon
          phone
          postalCode
          subDistrictID
          subDistrict {
            id
            name
            districtId
            district {
              id
              name
              cityId
              city {
                id
                name
                provinceId
                province {
                  id
                  name
                  countryId
                  country {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        isDeletable
        code
        default
        status
        name
        profileID
        updatedAt
        isPos
      }
    }
  }
`
export const MUTATION_CREATE_WAREHOUSE = gql`
  mutation warehouseCreate($input: CreateWarehouseInput!) {
    createWarehouse(input: $input) {
      warehouse {
        id
        address {
          id
          address
          email
          lat
          lon
          phone
          postalCode
          subDistrictID
          subDistrict {
            id
            name
            districtId
            district {
              id
              name
              cityId
              city {
                id
                name
                provinceId
                province {
                  id
                  name
                  countryId
                  country {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        isDeletable
        code
        default
        status
        name
        profileID
        updatedAt
        isPos
      }
    }
  }
`
export const MUTATION_UPDATE_WAREHOUSE = gql`
  mutation warehouseUpdate($input: UpdateWarehouseInput!) {
    updateWarehouse(input: $input) {
      warehouse {
        id
        address {
          id
          address
          email
          lat
          lon
          phone
          postalCode
          subDistrictID
          subDistrict {
            id
            name
            districtId
            district {
              id
              name
              cityId
              city {
                id
                name
                provinceId
                province {
                  id
                  name
                  countryId
                  country {
                    id
                    name
                  }
                }
              }
            }
          }
        }
        isDeletable
        code
        default
        status
        name
        profileID
        updatedAt
        isPos
      }
    }
  }
`
export const MUTATION_DELETE_WAREHOUSE = gql`
  mutation warehouseDelete($input: DeleteWarehouseInput!) {
    deleteWarehouse(input: $input) {
      warehouse {
        id
      }
    }
  }
`

/* ADDRESS */
export const QUERY_GET_LIST_COUNTRIES = gql`
  query countryList {
    countries {
      id
      name
    }
  }
`
export const QUERY_GET_LIST_PROVINCES = gql`
  query provinceList($countryId: Int!) {
    provinces(countryId:$countryId){
      id
      name
    }
  }
`
export const QUERY_GET_LIST_CITIES = gql`
  query cityList($provinceId: Int!) {
    cities(provinceId:$provinceId){
      id
      name
    }
  }
`
export const QUERY_GET_LIST_DISTRICTS = gql`
  query districtList($cityId: Int!) {
    districts(cityId:$cityId){
      id
      name
    }
  }
`
export const QUERY_GET_LIST_SUBDISTRICTS = gql`
  query subDistrictList($districtId: Int!) {
    subDistricts(districtId:$districtId){
      id
      name
      postalCode
    }
  }
`

/* CUSTOMER */
export const QUERY_GET_LIST_CUSTOMERS = gql`
  query customerList($search: String!, $limit: Int) {
    customers(search: $search, limit: $limit) {
      id
    	firstName
    	lastName
      phone
    }
  }
`
export const MUTATION_CREATE_CUSTOMER = gql`
  mutation customerCreate($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        id
        firstName
        lastName
        phone
      }
    }
  }
`
export const QUERY_GET_CUSTOMER = gql`
  query customer($id: Int!) {
    customer(id: $id) {
      id
      customerAddress {
        name
        phone
        address
        country
        province
        city
        district
        subDistrict
        postalCode
        billingName
        billingPhone
        billingAddress
        billingCountry
        billingProvince
        billingCity
        billingDistrict
        billingSubDistrict
        billingPostalCode
      }
    }
}
`
export const MUTATION_CREATE_SALES_ORDER = gql`
  mutation salesOrderCreate($input: CreateSalesOrderInput!) {
    createSalesOrder(input: $input) {
      order {
        id
      }
    }
  }
`

/* TAXES */
export const MUTATION_UPDATE_TAX = gql`
  mutation taxUpdate($input: UpdateTaxInput!) {
    updateTax(input: $input) {
      profile {
        id
        name
        mwhActivated
        tax {
          setting
          rate
        }
        isShipper
        invoice {
          isAutoCreate
          paymentTerms
          statusAutoCreate
          termsAndConditions
          isAutoPaid
          paymentDateOption
        }
      }
    }
  }
`

/* SHIPPER */
export const QUERY_GET_SHIPPER = gql`
  query shipper {
    shipper {
      id 
      couriers {
        id
        image
        name
        status
        group
      }
    }
  }
`
export const MUTATION_UPDATE_SHIPPER = gql`
  mutation shipperUpdate($input: UpdateShipperUserInput!) {
    updateShipper(input: $input) {
      shipper {
        id
        couriers {
          id
          image
          name
          status
          group
        }
      }
    }
  }
`

/* INVOICE */
export const MUTATION_UPDATE_INVOICE = gql`
  mutation invoiceUpdate($input: UpdateInvoiceInput!) {
    updateInvoice(input: $input) {
      profile {
        id 
        name
        mwhActivated
        tax {
          rate
          setting
        }
        isShipper
        invoice {
          isAutoCreate
          paymentTerms
          statusAutoCreate
          termsAndConditions
          isAutoPaid
          paymentDateOption
        }
      }
    }
  }
`

/* INVENTORY */
export const QUERY_GET_LIST_INVENTORIES_QTY = gql`
  query InventoryList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: InventoryFilter, $sortBy: InventoryOrderByInput) {
    qtyInventories(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      edges {
        node {
          id
          imageUrl
          productName
          profileId
          variantName
          variantSku
          warehouses {
            id
            warehouseId
            availableToSell
            name
            onHandTotal
            reservedOrders
            reservedPromotion
            picked
            packed
            updatedAt
            promotions {
              id
              quantity
              store {
                navbarId
              }
            }
            orders {
              id
              quantity
            }
            #onHandDamage
            #onHandNormal
            #handover
            #inTransit
            #waitingInbound
            #inbounded
            #waitingOutbound
          }
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_INVENTORIES_QTY = gql`
  query InventoryPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: InventoryFilter, $sortBy: InventoryOrderByInput) {
    qtyInventories(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`
export const QUERY_GET_TOTAL_INVENTORY = gql`
  query qtyInventories($filterBy: InventoryFilter) {
    qtyInventories(filterBy: $filterBy) {
      totalCount
    }
  }
`

export const QUERY_GET_LIST_STOCK_ADJUSTMENT = gql`
  query stockAdjustmentList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockAdjustmentFilterByInputs, $sortBy: StockAdjustmentSort) {
    stockAdjustments(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      edges {
        node {
          id
          status
          warehouseName
          totalSkuAdjusted
          createdBy
          receivedBy
          updatedAt
          actionAvailabilities {
            name
            group {
              name
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_STOCK_ADJUSTMENT = gql`
  query stockAdjustmentPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockAdjustmentFilterByInputs, $sortBy: StockAdjustmentSort) {
    stockAdjustments(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_DETAIL_STOCK_ADJUSTMENT = gql`
  query stockAdjustmentDetail($id: Int!) {
    stockAdjustment(id: $id) {
      id
      status
      warehouseName
      reference
      totalSku
      totalSkuAdjusted
      totalQtyAdjusted
      createdBy
      receivedBy
      createdAt
      updatedAt
      actionAvailabilities {
        name
        group {
          name
        }
      }
      itemLines {
        id
        image
        name
        options {
          type
          option
        }
        sku
        qtyOnHand
        newQtyOnHand
        qtyAdjusted
      }
    }
  }
`

export const QUERY_GET_LIST_OUTBOUND = gql`
  query stockOutboundList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockOutboundFilter, $sortBy: StockOutboundSort) {
    stockOutbounds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      edges {
        node {
          id
          status
          warehouse
          reference
          totalQty
          totalActualQty
          createdBy
          receivedBy
          updatedAt
          actionAvailabilities {
            name
            group {
              name
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_OUTBOUND = gql`
  query stockOutboundPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockOutboundFilter, $sortBy: StockOutboundSort) {
    stockOutbounds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_DETAIL_OUTBOUND = gql`
  query stockOutboundDetail($id: Int!) {
    stockOutbound(id: $id) {
      id
      status
      warehouse
      reference
      totalQty
      totalActualQty
      totalSku
      totalOutboundedQty
      createdBy
      receivedBy
      createdAt
      updatedAt
      actionAvailabilities {
        name
        group {
          name
        }
      }
      itemLines {
        id
        image
        name
        options {
          type
          option
        }
        sku
        qtyToOutbound
        actualQtyOutbound
      }
    }
  }
`

export const QUERY_GET_LIST_STOCK_HISTORY = gql`
  query stockHistoryList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockHistoryFilterByInputs, $startDate: String, $endDate: String) {
    stockHistoryList(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          productName
          productSku
          warehouseName
          bin {
            binName
          }
          locationId
          batchId
          type
          typeRefId
          qtyIn
          qtyOut
          qtyOnHand
          updatedAt
          storeName
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_STOCK_HISTORY = gql`
  query stockHistoryPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockHistoryFilterByInputs, $startDate: String, $endDate: String) {
    stockHistoryList(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageCursors {
        page
        startCursor
        endCursor
      }
      pageInfo  {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const QUERY_GET_LIST_INVENTORY_DAYS_OUT_OF_STOCK = gql`
  query inventoryDaysOutOfStockList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: ForecastingFilter, $startDate: String, $endDate: String) {
    inventoryForecasting(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          productName
          productImage
          options
          sku
          warehouseName
          onHandTotal
          avgQtySold
          dayOutOfStock
          outOfStockDate
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_INVENTORY_DAYS_OUT_OF_STOCK = gql`
  query inventoryDaysOutOfStockPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: ForecastingFilter, $startDate: String, $endDate: String) {
    inventoryForecasting(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageCursors {
        page
        startCursor
        endCursor
      }
      pageInfo  {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const QUERY_GET_INVENTORY_SETTINGS = gql`
  query settingsInventory{
    inventorySettings {
      reservedStockRules {
        selectedStatus
      }
      lowStockAlert {
        isActive
        quantity
        emailAt
        emailRecipients
      }
      priorityStore {
        isActive
        quantity
        storeAccount {
          id
          name
        }
      }
    }
  }
`

export const MUTATION_EXPORT_STOCK_HISTORY = gql`
  mutation exportStockHistory($input: ExportStockHistoryInput) {
    exportStockHistory(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_UPDATE_INVENTORY_SETTINGS = gql`
  mutation inventorySettingsUpdate($input: UpdateInventorySettingsInput!) {
    updateInventorySettings(input: $input) {
      success
      message
    }
  }
`

/* PRINT SETTING */
export const QUERY_GET_PRINT_SETTINGS = gql` 
  query settingsPrint {
    printDocumentSettings {
      storeName
      storePhoneNumber
      channelLogo
      logisticLogo
      brandLogo
      brandLogoUrl
      sellerName
      sellerPhone
      packagingInfo
      forstokOrderID
      buyerNote
      sellerNote
      sellerNotes
      productName
      variantName
      skuCode
      quantityOrdered
      withMargin
    }
  }
`

export const MUTATION_UPDATE_PRINT_SETTINGS = gql` 
  mutation printSettingsUpdate($input: UpdatePrintDocumentSettingsInput!) {
    updatePrintDocumentSettings(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_SUBMIT_LISTING = gql`
  mutation actionListing($input: InputActionListingPayload!) {
    actionListing(input: $input) {
      message
      errors {
        message
        row
        variantIndex
        imageIndex
        key
      }
    }
  }
`

export const MUTATION_SUBMIT_PRICE = gql`
  mutation actionPriceListing($input: [InputActionPriceListingPayload!]) {
    actionPriceListing(input: $input) {
      message
      errors {
        message
        row
      }
    }
  }
`

/* CATEGORY MAPPING */
export const QUERY_GET_LIST_CATEGORY_MAPPING = gql` 
  query categoryMappingsList($after: String, $before: String, $first: Int, $last: Int, $search: String, $page: Int) {
    categoryMappings(after: $after, before: $before, first: $first, last: $last, search: $search, page: $page) {
      edges {
        node {
          id
          name
          totalSku
          details {
            storeId
            storeName
            channelId
            channelName
            channelIcon
            channelInitial
            channelColor
            hasCategory
            category {
              labelArr
              valueArr
              value
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_CATEGORY_MAPPING = gql` 
  query categoryMappingsPage($after: String, $before: String, $first: Int, $last: Int, $search: String, $page: Int) {
    categoryMappings(after: $after, before: $before, first: $first, last: $last, search: $search, page: $page) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const MUTATION_ACTION_CATEGORY_MAPPING = gql` 
  mutation actionCategoryMapping($input: InputActionCategoryMapping!) {
    actionCategoryMapping(input: $input) {
      success
      listCategoryMapping {
        id
        name
        totalSku
        details {
          storeId
          storeName
          channelId
          channelName
          channelIcon
          channelInitial
          channelColor
          hasCategory
          category {
            labelArr
            valueArr
            value
          }
        }
      }
    }
  }
`

/* DASHBOARD ANNOUNCEMENT*/
export const QUERY_GET_ANNOUNCEMENT_LIST = gql`
  query announcement {
    announcement {
      message
      updatedAt
    }
  }
`

/* Reporting */
export const QUERY_GET_LIST_REPORTING_DASHBOARD = gql` 
  query reportingDashboard {
    reportings {
      id
      groups {
        name
        reporting {
          id
          dashboardId
          isActive
          name
        }
      }
    }
  }
`

export const QUERY_GET_REPORTING_GUEST_TOKEN = gql` 
  query reportingGuestToken ($dashboardId: String!) {
    reportingGuestToken (dashboardId: $dashboardId) {
      token
    }
  }
`

export const QUERY_GET_TRANSACTIONS = gql`
  query financeTransactions($search: String, $searchBy: String, $filterBy: FinanceTransactionFilterBy, $startDate: String, $endDate: String, $page: Int!, $limit: Int!) {
    financeTransactions(search: $search, searchBy: $searchBy, filterBy: $filterBy, startDate: $startDate, endDate: $endDate, page: $page, limit: $limit) {
      nodes {
        id
        createdDate
        store {
          name
          imageUrl
          channelInitial
          channelColor
        }
        type
        description
        salesOrderId
        invoiceId
        channelOrderId
        description
        debitAmount
        creditAmount
        profileId
      }
    }
  }
`

export const QUERY_GET_PAGE_TRANSACTIONS = gql`
  query financeTransactions($search: String, $searchBy: String, $filterBy: FinanceTransactionFilterBy, $startDate: String, $endDate: String, $page: Int!, $limit: Int!) {
    financeTransactions(search: $search, searchBy: $searchBy, filterBy: $filterBy, startDate: $startDate, endDate: $endDate, page: $page, limit: $limit) {
      totalCount
      totalPageCount
    }
  }
`

export const QUERY_GET_POPUP_TRANSACTION = gql`
  query popUpFinanceTransaction($type: String!) {
    popUpFinanceTransaction(type: $type) {
      descriptions
    }
  }
`

export const MUTATION_CREATE_FINANCE_TRANSACTION = gql`
  mutation createFinanceTransaction($input: CreateFinanceTransactionInput!) {
    createFinanceTransaction(input: $input) {
      message
    }
  }
`

/* CHAT */
export const QUERY_GET_CHAT_STORES = gql`
  query chatStores {
    chatStores {
      storeId
      storeName
      isActive
      channelId
      channelName
      channelColor
      channelImage
      channelInitials
      unreadCount
      isSyncing
    }
  }
`

export const QUERY_GET_CHAT_ROOMS = gql`
  query chatRooms($after: String, $before: String, $first: Int, $last: Int, $search: String, $sortBy: LinkChatRoomByInput, $storeId: Int, $filterBy: ChatFilterByInputs) {
    chatRooms(first:$first, last:$last, after:$after, before:$before, search:$search, sortBy:$sortBy, storeId: $storeId, filterBy:$filterBy) {
      nodes {
        id
        customerName
        unreadCount
        roomType
        isLastMsgFromBuyer
        solved {
          solvedAt
          userId
          solvedBy
        }
        lastMessage
        updatedAt
        storeId
        store {
          storeId
          storeName
          isActive
          channelId
          channelName
          channelColor
          channelImage
          channelInitials
        }
        isSyncing
        isFirstSync
      }
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_CHAT_MESSAGES = gql`
  query chatMessages($after: String, $before: String, $first: Int, $last: Int, $sortBy: LinkChatMessageByInput, $roomId: String!) {
    chatMessages(first:$first, last:$last, after:$after, before:$before, sortBy:$sortBy, roomId: $roomId) {
      nodes {
        createdAt
        messages {
          id
          roomId
          storeId
          senderName
          isFromBuyer
          status
          createdAt
          messageType
          messageText
          messageProductId
          messageProduct {
            id
            productId
            masterProductId
            name
            imageUrl
            quantity
          }
          messageUrl
        }
      }
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_CHAT_PRODUCTS = gql`
  query chatProducts($after: String, $before: String, $first: Int, $last: Int, $search: String, $sortBy: LinkChatProductByInput, $storeId: Int!) {
    chatProducts(first:$first, last:$last, after:$after, before:$before, search:$search, sortBy:$sortBy, storeId: $storeId) {
      nodes {
        id
        productId
        masterProductId
        name
        imageUrl
        quantity
        storeId
        store {
          storeId
          storeName
          isActive
          channelId
          channelName
          channelColor
          channelImage
          channelInitials
        }
      }
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const MUTATION_SEND_MESSAGE_CHAT = gql`
  mutation chatSendMessage($input: chatSendMessageInput!) {
    chatSendMessage(input: $input) {
      success
      message
      content {
        id
        roomId
        storeId
        senderName
        isFromBuyer
        status
        createdAt
        messageType
        messageText
        messageProductId
        messageProduct {
          id
          productId
          masterProductId
          name
          imageUrl
          quantity
        }
        messageUrl
      }
    }
  }
`

export const MUTATION_SYNC_CHAT = gql`
  mutation chatSync($input: chatSyncInput!) {
    chatSync(input: $input) {
      success
      message
    }
  }
`

export const MUTATION_AUTH_STORE_CHAT = gql`
  mutation chatAuthorize($input: chatAuthorizeInput!) {
    chatAuthorize(input: $input) {
      success
      message
      content {
        storeId
        storeName
        isActive
        channelId
        channelName
        channelColor
        channelImage
        channelInitials
        isSyncing
      }
    }
  }
`

export const SUBSCRIPTION_CHAT_UPDATE = gql`
  subscription chatUpdated($profileId: String!) {
    chatUpdated(profileId: $profileId){
      profileId
      eventType
      id
      roomId
      storeId
      senderName
      isFromBuyer
      status
      createdAt
      messageType
      messageText
      messageProductId
      messageProduct {
        id
        productId
        masterProductId
        name
        imageUrl
        quantity
      }
      messageUrl
    }
  }
`

export const SUBSCRIPTION_CHAT_ROOM_UPDATE = gql`
  subscription chatRoomUpdated($profileId: String!) {
    chatRoomUpdated(profileId: $profileId){
      profileId
      eventType
      id
      customerName
      unreadCount
      roomType
      isLastMsgFromBuyer
      solved {
        solvedAt
        userId
        solvedBy
      }
      lastMessage
      updatedAt
      storeId
      store {
        storeId
        storeName
        isActive
        channelId
        channelName
        channelColor
        channelImage
        channelInitials
      }
      isSyncing
      isFirstSync
    }
  }
`

export const SUBSCRIPTION_CHAT_STORE_UPDATE = gql`
  subscription chatStoreUpdated($profileId: String!) {
    chatStoreUpdated(profileId: $profileId){
      storeId
      storeName
      isActive
      channelId
      channelName
      channelColor
      channelImage
      channelInitials
      unreadCount
      isSyncing
    }
  }
`

export const QUERY_FAILED_EXPORT = gql`
  query failedExport($profileId: Int!, $group: String!) {
    failedEvent(profileId: $profileId, group: $group) {
      Total
      Message
      Url
    }
  }
`

export const QUERY_GET_LIST_OPEN_SALES_ORDER_AT_PRINT_SHIPPING_LABELS = gql`
  query openOrders($ids: [String!]) {
    openOrders(ids: $ids) {
      edges {
        node {
          id
          accountName
          localId
          localName
          orderedAt
          note
          ...channelinfo
          dropship
          dropshipper {
            name
            phone
          }
          address {
            name
            address1
            address2
            subDistrict
            district
            city
            province
            country
            phone
            postalCode
          }
          itemLines{
            id
            name
            variantName
            quantity
            notes
            sku
            weight
            bins {
              binName
              quantity
            }
            orderItemLineBundles{
              id
              name
              sku
              totalQuantity
              bins {
                binName
                quantity
              }
            }
            status{
              actionAvailabilities{
                ...actionAvailability
              }
            }
            fulfillmentItemLine {
              id
              fulfillment {
                id
                trackingNumber
                bookingCode
              }
            }
            shippingProviderType
            slaShipping
          }
          fulfillments{
            trackingLogo
            trackingCompany
            logisticDestinationCode
          }
          cashless
          cod
        }
      }
    }
  }
  ${channelinfo}
  ${actionAvailability}
`

export const QUERY_GET_OPEN_PRINT_SETTINGS = gql` 
  query openPrintDocumentSettings {
    openPrintDocumentSettings {
      storeName
      storePhoneNumber
      channelLogo
      logisticLogo
      brandLogo
      brandLogoUrl
      sellerName
      sellerPhone
      packagingInfo
      forstokOrderID
      buyerNote
      sellerNote
      sellerNotes
      productName
      variantName
      skuCode
      quantityOrdered
      withMargin
    }
  }
`

export const QUERY_GET_PAGE_PROMOTION = gql`
  query promotionPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $filterBy: PromotionFilterByInputs, $searchBy: String, $sortBy: LinkOrderPromotionByInput, $startDate: String, $endDate: String,$status:String) {
    promotions(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, filterBy:$filterBy, searchBy:$searchBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate, status:$status) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_LIST_PROMOTION = gql`
  query promotionList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $filterBy: PromotionFilterByInputs, $searchBy: String, $sortBy: LinkOrderPromotionByInput, $startDate: String, $endDate: String, $status:String) {
    promotions(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, filterBy:$filterBy, searchBy:$searchBy, sortBy:$sortBy, startDate: $startDate, endDate: $endDate, status:$status) {
      edges {
        node {
          id
          channelPromotionId
          store {
            storeId
            storeName
            channelId
            channelName
            channelColor
            channelImage
            channelInitials
            navbarId
          }
          name
          type
          totalSku
          startAt
          endAt
          status
          updatedAt
        }
      }
    }
  }
`

export const QUERY_GET_DETAIL_PROMOTION = gql` 
  query promotionDetail($id: String!) {
    promotion(id:$id) {
      id
      channelPromotionId
      store {
        storeId
        storeName
        channelId
        channelName
        channelColor
        channelImage
        channelInitials
        navbarId
      }
      name
      totalSku
      startAt
      endAt
      status
      type
      updatedAt
      itemLines {
        imageUrl
        name
        sku
        discount
        salePrice
        price
        quantity
        maxOrder
        bundle {
          name
          sku
          quantity
          productId
          totalBundle
        }
      }
    }
  }
`

export const QUERY_GET_TOTAL_PROMOTION = gql`
  query promotionTotal($first: Int, $filterBy: PromotionFilterByInputs, $status:String) {
    promotions(first:$first, filterBy:$filterBy, status:$status) {
      totalCount
    }
  }
` 

export const SUBSCRIPTION_PROMOTION_UPDATE = gql`
  subscription promotionUpdated($profileId: String!) {
    promotionUpdated(profileId: $profileId){
      profileId
      promotionId
      accountId
      previousStatus
      currentStatus
      previousStatuses
      currentStatuses
      eventType 
    }
  }
`

export const QUERY_DOWNLOAD_EXCEL_DOCUMENT = gql`
  query downloadExcelDocument($input: InputDownloadExcelDocument!) {
    downloadExcelDocument(input: $input) {
      status
      message
    }
  }
`

export const MUTATION_UPDATE_EXCEL_DOCUMENT = gql`
  mutation uploadExcelDocument($input: InputUploadExcelDocument!) {
    uploadExcelDocument(input: $input) {
      status
      message
    }
  }
`

export const QUERY_GET_LIST_INBOUND = gql`
  query InboundList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockInboundFilter, $sortBy: StockInboundSort) {
    stockInbounds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      edges {
        node {
          id
          status
          quantity
          actualQuantity
          putawayStatus
          quantityPutaway
          actualQuantityPutaway
          warehouse
          reference
          createdBy
          receiveBy
          updatedAt
          createdAt
          actionAvailabilities {
            name
            group {
              action
              name
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_INBOUND = gql`
  query InboundPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: StockInboundFilter, $sortBy: StockInboundSort) {
    stockInbounds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, sortBy:$sortBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_DETAIL_INBOUND = gql` 
  query StockInboundDetail($id: Int!) {
    stockInbound(id:$id) {
      id
      status
      quantity
      actualQuantity
      warehouse
      reference
      createdBy
      receiveBy
      updatedAt
      createdAt
      inboundAt
      totalSku
      totalInboundedQty
      itemLines {
        id
        image
        name
        options {
          type
          option
        }
        inboundDate
        sku
        inboundStatus
        putawayStatus
        quantityInbound
        actualQuantityInbound
      }
      putaways {
        id
        createdAt
        status
        warehouse
        quantity
        actualQuantity
      }
      goodReceives {
        id
        goodReceiveId
        itemLines {
          id
          name
          sku
          deltaQuantity
          newQuantity
          oldQuantity
          newStatus
          oldStatus            
        }
        newStatus
        oldStatus
        createdAt
      } 
      actionAvailabilities {
        name
        group {
          action
          name
        }
      }
    }
  }
`

export const QUERY_GET_PROMOTION_BY_VARIANT_ID = gql`
  query promotionsByVariantsId( $id: String!, $accountId: Int!) {
    promotionsByVariantsId(id: $id, accountId: $accountId) {
      id
      type
      startDate
      endDate
      status
      regularPrice
      discountPrice
      store {
        navbarId
      }
    }
  }
`

export const QUERY_GET_LIST_SALES_ORDER_IN_PACKAGE = gql`
  query orderListPackage($first: Int, $search: String, $searchBy: String) {
    orders(first:$first, search:$search, searchBy:$searchBy) {
      edges {
        node {
          id
          packed
          itemLines {
            id
            sku
            consignment
            name
            variantName
            itemImageUrl
            quantity
            barcode
            orderItemLineBundles{
              id
              imageUrl
              name
              sku
              totalQuantity
              barcode
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_VARIANT_PRODUCTS_AT_ADD = gql`
  query GetVariantProductsAdd($limit: Int, $offset: Int, $search: Any, $searchBy: String, $warehouseIds: [Int], $ids: [Int], $isBundle: Boolean) {
    GetVariantProducts(limit: $limit, offset: $offset, search: $search, searchBy: $searchBy, warehouseIds: $warehouseIds, ids: $ids, isBundle: $isBundle) {
      id
      sku
      imageUrl
      productId
      productName
      variantName
      quantityOnHand
      reservedQuantity
      barcode
      regularPrice
      costPrice
      salePrice
      warehouse {
        id
        name
        quantityOnHand
        reservedQuantity
        availableQuantity
      }
      options {
        type
        option
      }
    }
  }
`
export const MUTATION_CREATE_QUANTITY = gql`
  mutation actionCreateQuantity($input: CreateQuantityInput!) {
    actionCreateQuantity(input: $input) {
      message
      errors {
        message
        row
        key
      }
    }
  }
`
export const QUERY_GET_VARIANT_PRODUCTS_AT_ADD_PRICE = gql`
  query GetVariantProductsAddPrice($limit: Int, $offset: Int, $search: Any, $searchBy: String, $warehouseIds: [Int], $ids: [Int], $isBundle: Boolean) {
    GetVariantProducts(limit: $limit, offset: $offset, search: $search, searchBy: $searchBy, warehouseIds: $warehouseIds, ids: $ids, isBundle: $isBundle) {
      id
      sku
      barcode
      imageUrl
      productId
      productName
      variantName
      regularPrice
      salePrice
      options {
        type
        option
      }
      listings {
        id
        listingId
        accountId
        price
        salePrice {
          salePrice
          startAt
          endAt
        }
      }
    }
  }
`

export const MUTATION_CONFIRM_RECEIVED = gql`
  mutation actionConfirmReceived($input: ConfirmReceiveInput!) {
    actionConfirmReceived(input: $input) {
      message
    }
  }
`

export const MUTATION_ACTION_PRICE_ADJUSTMENT = gql`
  mutation actionPriceAdjustment($input: InputActionPriceAdjustmentPayload!) {
    actionPriceAdjustment(input: $input) {
      message
    }
  }
`

export const MUTATION_MARK_AS_INCOMPLETE = gql`
  mutation markAsIncomplete($input: MarkAsIncompleteInput!){
    markAsIncomplete(input: $input) {
      status
      message
    }
  }
`

export const QUERY_GET_LIST_PRICE_ADJUSTMENT = gql`
  query priceAdjustmentList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PriceAdjustmentFilterByInputs) {
    priceAdjustments(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy) {
      edges {
        node {
          id
          status
          totalSku
          updatedBy
          createdAt
          source
          actionAvailabilities {
            name 
            group {
              action
              name
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_PAGE_PRICE_ADJUSTMENT = gql`
  query priceAdjustmentPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PriceAdjustmentFilterByInputs) {
    priceAdjustments(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_DETAIL_PRICE_ADJUSTMENT = gql` 
  query priceAdjustmentDetail($id: Int!) {
    priceAdjustment(id:$id) {
      id
      status
      totalSku
      updatedBy
      createdAt
      createdBy
      approvedAt
      approvedBy
      referenceNumber
      source
      actionAvailabilities {
        name 
        group {
          action
          name
        }
      }
      itemLines {
        productId
        sku
        productName
        store {
          storeId
          storeName
          channelId
          channelName
          channelColor
          channelImage
          channelInitials
        }
        oldPrice
        oldSalePrice
        newPrice
        newSalePrice
        saleStartAt
        saleEndAt
      }
    }
  }
`

export const QUERY_GET_PAGE_PRICE_HISTORY = gql`
  query priceHistoryPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PriceHistoryFilterByInputs, $startDate: String, $endDate: String) {
    priceHistories(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, startDate: $startDate, endDate: $endDate) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_LIST_PRICE_HISTORY = gql`
  query priceHistoryList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $search: String, $searchBy: String, $filterBy: PriceHistoryFilterByInputs, $startDate: String, $endDate: String) {
    priceHistories(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, search:$search, searchBy:$searchBy, filterBy:$filterBy, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          source
          adjustmentId
          productName
          sku
          store {
            storeId
            storeName
            channelName
            channelColor
            channelImage
            channelInitials
          }
          oldPrice
          newPrice
          oldSalePrice
          newSalePrice
          updatedAt
        }
      }
    }
  }
`

export const MUTATION_ACTION_CONFIRM_PRICE_ADJUSTMENT = gql`
  mutation actionConfirmPriceAdjustment($input: InputActionConfirmPricePayload!) {
    actionConfirmPriceAdjustment(input: $input) {
      message
    }
  }
`

export const SUBSCRIPTION_PRICE_ADJUSTMENT_UPDATE = gql`
  subscription priceAdjustmentUpdated($profileId: String!) {
    priceAdjustmentUpdated(profileId: $profileId){
      profileId
      adjustmentId
      previousStatus
      currentStatus
      previousStatuses
      currentStatuses
      eventType
    }
  }
`

export const QUERY_GET_DETAIL_INVOICE_AT_RETURN = gql` 
  query returnDetailInvoice($id: Int!) {
    invoice(id:$id) {
      id
      return {
        id
        status
        createdAt
        warehouse {
          name
        }
        orderitemLines {
          quantityReturned
        }
      }
      orderInvoice {
        id
        orderID
        order {
          id
          localName
          localId
          itemLines {
            id
            sku
            consignment
            name
            variantName
            itemImageUrl
            quantity
            quantityReturned
            barcode
            orderItemLineBundles{
              id
              imageUrl
              name
              sku
              totalQuantity
              quantityReturned
              barcode
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_LIST_SALES_ORDER_IN_RETURN = gql`
  query orderListReturn($first: Int, $search: String, $searchBy: String) {
    orders(first:$first, search:$search, searchBy:$searchBy) {
      edges {
        node {
          id
          invoices {
            id
            return {
              id
            }
          }
          itemLines {
            id
            sku
            consignment
            name
            variantName
            itemImageUrl
            quantity
            barcode
            orderItemLineBundles{
              id
              imageUrl
              name
              sku
              totalQuantity
              barcode
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_LIST_INVOICE_IN_RETURN = gql`
  query invoiceList($first: Int, $search: String, $searchBy: String) {
    invoices(first:$first, search:$search, searchBy:$searchBy) {
      edges {
        node {
          id
          actionAvailabilities {
            ...actionAvailability
          }
          orderInvoice {
            id
            orderID
            order {
              id
              localName
              localId
              itemLines {
                id
                sku
                consignment
                name
                variantName
                itemImageUrl
                quantity
                quantityReturned
                barcode
                status {
                  name
                }
                orderItemLineBundles{
                  id
                  imageUrl
                  name
                  sku
                  totalQuantity
                  quantityReturned
                  barcode
                }
              }
            }
          }
        }
      }
    }
  }
  ${actionAvailability}
`

export const QUERY_GET_PAGE_ARCHIVED = gql`
  query productArchivedsPage($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $sortBy: LinkProductArchivedByInput, $search: String, $searchBy: String) {
    productarchiveds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, sortBy:$sortBy, search:$search, searchBy:$searchBy) {
      totalCount
      totalPageCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      pageCursors {
        page
        startCursor
        endCursor
      }
    }
  }
`

export const QUERY_GET_LIST_ARCHIVED = gql`
  query productArchivedsList($afterCursor: String, $beforeCursor: String, $first: Int, $last: Int, $sortBy: LinkProductArchivedByInput, $search: String, $searchBy: String){
    productarchiveds(first:$first, last:$last, after:$afterCursor, before:$beforeCursor, sortBy:$sortBy, search:$search, searchBy:$searchBy){
      edges{
        node{
          id
          productId
          variants {
            id
            sku
            variantName
          }
          archivedAt
        }
      }
    }
  }
`

export const MUTATION_ARCHIVED = gql`
  mutation archivedProducts($input: archivedProductsInput!){
    archivedProducts(input:$input){
      message
      successProducts
      failedProducts
    }
  }
`

export const MUTATION_UNARCHIVED = gql`
  mutation unarchivedProducts($input: unarchivedProductsInput!){
    unarchivedProducts(input:$input){
      message
    }
  }
`

export const MUTATION_DELETE_ARCHIVED = gql`
  mutation deleteArchivedProduct($input: deleteArchivedProductInput!){
    deleteArchivedProduct(input:$input){
      message
    }
  }
`

export const MUTATION_ACTION_STOCK_SYNC = gql`
  mutation stockSync($input: StockSyncInput!) {
    stockSync(input: $input) {
      success
      message
    }
  }
`
export const QUERY_GET_VARIANT_PRODUCTS_STOCK = gql`
  query GetVariantProductsStock($limit: Int, $offset: Int, $search: Any, $searchBy: String, $warehouseIds: [Int], $isBundle: Boolean) {
    GetVariantProducts(limit: $limit, offset: $offset, search: $search, searchBy: $searchBy, warehouseIds: $warehouseIds, isBundle: $isBundle) {
      id
      sku
      imageUrl
      productId
      productName
      barcode
      regularPrice
      salePrice
      warehouse {
        id
        name
        quantityOnHand
        reservedQuantity
        availableQuantity
      }
      options {
        type
        option
      }
    }
  }
`
export const QUERY_GET_VARIANT_PRODUCTS_BUNDLE = gql`
  query GetVariantProductsBundle($limit: Int, $offset: Int, $search: Any, $searchBy: String) {
    GetVariantProducts(limit: $limit, offset: $offset, search: $search, searchBy: $searchBy) {
      id
      productId
      masterProductId
      variantName
      productName
      sku
      imageUrl
      regularPrice
      options {
        type
        option
      }
    }
  }
`
export const QUERY_GET_VARIANT_PRODUCTS_SALES = gql`
  query GetVariantProductsSales($limit: Int, $offset: Int, $search: Any, $searchBy: String, $warehouseIds: [Int]) {
    GetVariantProducts(limit: $limit, offset: $offset, search: $search, searchBy: $searchBy, warehouseIds: $warehouseIds) {
      id
      variantName
      productName
      sku
      imageUrl
      regularPrice
      salePrice
      warehouse {
        id
        name
        availableQuantity
      }
      options {
        type
        option
      }
    }
  }
`